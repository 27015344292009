import { Link, LinkProps, To, useLocation } from "react-router-dom";

import { cx } from "utils";


interface StatefulLinkProps extends Omit<LinkProps, "to"> {
  to?: To,
  hoverClassName?: string;
  disabled?: boolean;
  addLocationToState?: boolean;
}


export default function StatefulLink(
  {
    to,
    onClick,
    id,
    state,
    className,
    hoverClassName,
    disabled = false,
    children,
    addLocationToState = false,
    viewTransition,
    ...linkProps
  }: StatefulLinkProps
) {

  const location = useLocation();

  if (to && !disabled) {
    return (
      <Link
        { ...linkProps }
        to={ to }
        onClick={ onClick }
        className={ cx(className, hoverClassName) }
        id={ id }
        state={ {
          backgroundLocation: addLocationToState ? location : undefined,
          ...state
        } }
        viewTransition={ viewTransition === undefined ? addLocationToState : viewTransition }
      >
        { children }
      </Link>
    );
  }

  return (
    <span
      className={ cx(className, !disabled && hoverClassName, disabled && "!cursor-default") }
      id={ id }
      onClick={ disabled ? undefined : onClick }
    >
      { children }
    </span>
  );

};
