import { Coupon } from "types/Coupons";

/**
 * Adding this "fake" coupon to annual subscriptions, so discount price calculation would be the same as for all the other plans and coupons.
 */
export const FixAnnualDiscountCoupon: Coupon = {

  coupon_code: "",
  coupon_name: "",
  coupon_type: "chargebee",
  added_at: new Date(),

  discount_type: "percentage",
  discount_value: 10

};
