import { useNewBillingPage } from "v2/hooks/pilot/useNewBillingPage";


export function useBillingPagePath() {
  const { newBillingPageSetting } = useNewBillingPage();

  if (newBillingPageSetting === "yes") {
    return "/v2/billing";
  } else {
    return "/billing";
  }
}
