import { useCallback, useMemo } from "react";
import { FetcherWithComponents } from "react-router-dom";
import _ from "lodash";

import { useFetcherData } from "v2/hooks/useFetcherData";

import { FetcherKeys } from "routes/dataroutes/Fetchers";

import Plan, { PlanPair } from "types/Plan";


type FetcherType = { plansPromise: Promise<PlanPair[]> };

export function usePlans(): {
  plansPromise: Promise<PlanPair[]> | undefined,
  planPairs: PlanPair[] | undefined,
  allPlans: Plan[] | undefined,
  fetcher: FetcherWithComponents<FetcherType>,
}
{
  const dataPromiseAccessor = useCallback((data: FetcherType) => data.plansPromise, []);

  const { promise, value, fetcher } = useFetcherData<PlanPair[], FetcherType>({
    fetcherKey: FetcherKeys.PLANS,
    fetcherHref: "/billing-data/plans",
    dataPromiseAccessor: dataPromiseAccessor
  });

  const allPlans = useMemo(() => {
    return _.compact(value?.flatMap(pp => [ pp.monthly, pp.annual ]));
  }, [ value ]);

  return {
    plansPromise: promise,
    planPairs: value,
    allPlans: allPlans,
    fetcher: fetcher!
  };
}
