import { Outlet } from "react-router-dom";

import ChargebeePortalProvider from "providers/ChargebeePortalProvider";
import ChargebeeProvider from "providers/ChargebeeProvider";
import CustomerIOProvider from "providers/CustomerIOProvider";
import FirstPromoterProvider from "providers/FirstPromoterProvider";
import ZohoProvider from "providers/ZohoProvider";


export default function ThirdPartyServicesLayout() {
  return (
    <>
      { /* support widget */ }
      <ZohoProvider/>

      { /* user related services */ }
      <FirstPromoterProvider/>
      <CustomerIOProvider/>

      <ChargebeeProvider
        publishableKey={ process.env.REACT_APP_APP_CHARGEBEE_PUBLISHABLE_KEY }
        site={ process.env.REACT_APP_APP_CHARGEBEE_SITE_NAME }
        enableGTMTracking={ true }
        enableGATracking={ true }
      >
        {/* TODO we don't necessarily need the portal provider here at this level, should only load that when on the Billing page */}
        <ChargebeePortalProvider>
          <Outlet/>
        </ChargebeePortalProvider>
      </ChargebeeProvider>
    </>
  );
};
