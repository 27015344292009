import type { SettingsFromApi } from "providers/ApiSettingsProvider";

export const isValidAmazonTLD = (tld?: string, settings?: SettingsFromApi): boolean => 
  tld === undefined || (settings?.availableTLDs.amazon?.includes(tld) ?? false);

export const isValidGoogleTLD = (tld?: string, settings?: SettingsFromApi): boolean => 
  tld === undefined || (settings?.availableTLDs.google?.includes(tld) ?? false);

export const isValidEbayTLD = (tld?: string, settings?: SettingsFromApi): boolean => 
  tld === undefined || (settings?.availableTLDs.ebay?.includes(tld) ?? false);

export const isValidRedfinTLD = (tld?: string, settings?: SettingsFromApi): boolean => 
  tld === undefined || (settings?.availableTLDs.redfin?.includes(tld) ?? false);

export const isValidWalmartTLD = (tld?: string, settings?: SettingsFromApi): boolean => 
  tld === undefined || (settings?.availableTLDs.walmart?.includes(tld) ?? false); 