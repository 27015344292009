import { useLocation } from "react-router-dom";

import Modal, { ModalContent, ModalSections, useCloseModal } from "components/Modal";


export default function BillingErrorModal() {
  // TODO location.state.error will hold the ActionError object
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();

  const onClose = useCloseModal();

  return (
    <Modal headline="Billing Error" onClose={ onClose } >
      <ModalContent>
        <ModalSections>
          <div>Some serious shit just happened</div>
        </ModalSections>
      </ModalContent>
    </Modal>
  );
};
