import {
  ProjectConfig,
  NewProjectConfig,
  CollectorType,
  InputConnectorConfig,
  OutputConfig,
  JsonUrlInputConfig,
  ListLiteralInputConfig,
  UploadJsonListConfig,
  UploadCsvListConfig,
  WebhookOutputConfig,
  CollectorConfig,
  WebhookInputConfig,
} from "providers/HostedScrapingProvider/types";

import { isValidCron } from 'components/hosted-scraping/cronUtils';
import type { SettingsFromApi } from "providers/ApiSettingsProvider";
import { 
  isValidAmazonTLD, 
  isValidGoogleTLD, 
  isValidEbayTLD, 
  isValidRedfinTLD, 
  isValidWalmartTLD 
} from "./hosted-scraping/edit-project-components/tldValidators";
import { inputValidatorFn, isAmazonProject, isAsyncUrlsProject, isEbayProject, isGoogleProject, isWalmartProject, isRedfinProject } from "sdecontent";

export enum ConfigProblem {
  InvalidInput,
  InvalidLiteralList,
  EmptyLiteralList,
  DuplicatesInListLiteral,
  InvalidOutput,
  InvalidWebhookURL,
  MissingWebhookURL,
  InvalidCron,
  InvalidCountryCode,
  InvalidTLD,
  InvalidUULE,
  InvalidLatitude,
  InvalidLongitude,
  InvalidNum,
  InvalidItemsPerPage,
  InvalidSortBy,
  MissingUploadedFile,
  InvalidUploadedFile,
  InvalidAdditionalParameters,
  BackendCostCalculationError,
  InvalidMaxCost,
  InvalidSessionNumber,
  InvalidRedfinURL,
}

export type ConfigProblemWithMessage = {
  problem: ConfigProblem;
  message?: string;
  multipleMessages?: string[];
};

export const isValidUrl = (url: string): boolean => {
  try {
    const u = new URL(url);
    return u.protocol === 'http:' || u.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

export const isValidOfDomain = (url: string, domains: string[]): boolean => {
  try {
    const u = new URL(url);
    if (u.protocol !== 'http:' && u.protocol !== 'https:') {
      return false;
    }
    const matchingHostname = domains.some((domain) => u.hostname.endsWith(domain));

    return matchingHostname;

  } catch (err) {
    return false;
  }
}

export const isValidRedfinUrl = (url: string): boolean => {
  return isValidOfDomain(url, ['redfin.com', 'redfin.ca']);
}

export const isValidAsin = (asin: string): boolean => {
  const asinRegex = /^[a-zA-Z0-9]{10}$/ug;
  return asinRegex.test(asin);
}

export const isValidQuery = (query: string): boolean => {
  return query.length > 0;
}

export const isValidWalmartProductId = (productId: string): boolean => {
  return productId.length > 0;
}

export const isValidWalmartCategory = (category: string): boolean => {
  return category.length > 0;
}

export const isValidEbayProductId = (productId: string): boolean => {
  return /^[0-9]{12}$/gu.test(productId);
}

// export const isValidEbayCondition = (condition: string | undefined): boolean => {
// }

// export const isValidEbayBuyingFormat = (buyingFormat: string | undefined): boolean => {
// }

// export const isValidEbayShowOnlyValue = (showOnly: string | undefined): boolean => {
// }

// export const isValidEbaySortBy = (sortBy: string | undefined): boolean => {
// }


// handle input commas
const validateCommaInputs = (projectType: CollectorType, allElements: string[]) => {
  switch (projectType) {
    case 'async_urls':
    case 'async_redfin_for_sale':
    case 'async_redfin_search':
      return !allElements.some(e => e.includes(',http'))
    default:
      return !allElements.some(e => e.includes(','))
  }
}


const isValidWalmartSortByOption = (sortByOption: string | undefined): boolean => {
  if (sortByOption === undefined) {
    return true;
  }
  return ['relevancy', 'helpful', 'submission-desc', 'submission-asc', 'rating-desc', 'rating-asc'].includes(sortByOption);
}


const isValidUULE = (uule?: string): boolean => {
  if (uule === undefined) {
    return true;
  }
  return true; // TODO I don't know the format of UULE
}

const checkCoordinateContentValidity = (coord?: string) => typeof coord === 'string' && /^[+-]?\d+(\.\d+)?$/.test(coord);
const isValidCoordinates = (latitude: number, longitude: number) => {
  if (!latitude && !longitude) return { isValid: true, reason: '' };
  if (latitude && !longitude) return { isValid: false, reason: 'bad long' };
  if (!latitude && longitude) return { isValid: false, reason: 'bad lat' };

  const isLatValid = checkCoordinateContentValidity(latitude.toString());
  const isLongValid = checkCoordinateContentValidity(longitude.toString());

  if (!isLatValid || !isLongValid) {
    return { isValid: false, reason: !isLatValid && !isLongValid ? 'both' : !isLatValid ? 'bad lat' : 'bad long' };
  }

  if (latitude < -90 || latitude > 90) return { isValid: false, reason: 'bad lat' };
  if (longitude < -180 || longitude > 180) return { isValid: false, reason: 'bad long' };

  return { isValid: true, reason: null };
};

const isValidNum = (num?: number | string, max?: number): boolean => {
  if (num === undefined || (typeof num === 'number' && num > 0)) {
    return true;
  }
  const parsedNum = Number.parseInt(num as string, 10);
  return !isNaN(parsedNum) && parsedNum > 0 && (max === undefined || parsedNum <= max);
}

const isValidCountryCode = (settings: SettingsFromApi, countryCode?: string): boolean => {
  const { availableCountryCodes } = settings;
  if (countryCode === undefined) {
    return true;
  }
  return availableCountryCodes.includes(countryCode) || countryCode === 'eu';
}

const isValidAditionalParams = (params?: string): boolean => {
  const allowedParams = ['hl', 'gl', 'ie', 'oe', 'start']

  if (!params) {
    return true;
  }

  const parsed = Object.fromEntries(new URLSearchParams(params));
  return Object.keys(parsed).every((k: string) => allowedParams.includes(k)) && !params.includes(' ');
}


const validateAsyncURLsAPIParams = (projectConfig: CollectorConfig, settingsFromApi: SettingsFromApi): ConfigProblemWithMessage[] => {
  const problems = [];

  if (!isValidCountryCode(settingsFromApi, projectConfig.apiParams?.countryCode as string)) {
    problems.push({ problem: ConfigProblem.InvalidCountryCode });
  }

  return problems;
}

const validateAmazonProjectAPIParams = (projectConfig: CollectorConfig, settingsFromApi: SettingsFromApi): ConfigProblemWithMessage[] => {
  const apiParams = projectConfig.apiParams;
  const problems = [];
  if (!isValidCountryCode(settingsFromApi, apiParams?.countryCode as string)) {
    problems.push({ problem: ConfigProblem.InvalidCountryCode });
  }
  if (!isValidAmazonTLD(apiParams?.tld as string)) {
    problems.push({ problem: ConfigProblem.InvalidTLD });
  }
  return problems;
}

const validateGoogleProjectAPIParams = (projectConfig: CollectorConfig, settingsFromApi: SettingsFromApi): ConfigProblemWithMessage[] => {
  const problems = [];
  const apiParams = projectConfig.apiParams;
  const validCoordsResult = isValidCoordinates(apiParams?.latitude as number, apiParams?.longitude as number);

  if (!isValidCountryCode(settingsFromApi, apiParams?.countryCode as string)) {
    problems.push({ problem: ConfigProblem.InvalidCountryCode });
  }
  if (!isValidUULE(apiParams?.uule as string)) {
    problems.push({ problem: ConfigProblem.InvalidUULE });
  }
  if (!validCoordsResult.isValid && (validCoordsResult.reason === 'both' || validCoordsResult.reason === 'bad lat')) {
    problems.push({ problem: ConfigProblem.InvalidLatitude })
  }
  if (!validCoordsResult.isValid && (validCoordsResult.reason === 'both' || validCoordsResult.reason === 'bad long')) {
    problems.push({ problem: ConfigProblem.InvalidLongitude })
  }
  if (!isValidGoogleTLD(apiParams?.tld as string)) {
    problems.push({ problem: ConfigProblem.InvalidTLD });
  }
  if (!isValidNum(apiParams?.num as number, 100)) {
    problems.push({ problem: ConfigProblem.InvalidNum });
  }
  if (!isValidAditionalParams(apiParams?.additionalParams as string)) {
    problems.push({ problem: ConfigProblem.InvalidAdditionalParameters });
  }
  return problems;
}

const validateWalmartProjectAPIParams = (projectConfig: CollectorConfig, settingsFromApi: SettingsFromApi): ConfigProblemWithMessage[] => {
  const problems = [];
  const apiParams = projectConfig.apiParams;
  if (!isValidWalmartTLD(apiParams?.tld as string)) {
    problems.push({ problem: ConfigProblem.InvalidTLD });
  }
  if (!isValidNum(apiParams?.page as number, 100)) {
    problems.push({ problem: ConfigProblem.InvalidNum });
  }
  if (!isValidWalmartSortByOption(apiParams?.sort as string | undefined)) {
    problems.push({ problem: ConfigProblem.InvalidSortBy });
  }
  return problems;
}

const validateEbayProjectAPIParams = (projectConfig: CollectorConfig, settingsFromApi: SettingsFromApi): ConfigProblemWithMessage[] => {
  const problems = [];
  const apiParams = projectConfig.apiParams;
  if (!isValidEbayTLD(apiParams?.tld as string)) {
    problems.push({ problem: ConfigProblem.InvalidTLD });
  }

  if (!isValidNum(apiParams?.page as number)) {
    problems.push({ problem: ConfigProblem.InvalidNum });
  }

  if (!isValidNum(apiParams?.items_per_page as number)) {
    problems.push({ problem: ConfigProblem.InvalidItemsPerPage });
  }

  // if (!isValidEbayCondition(apiParams?.condition as string)) {
  //   problems.push({ problem: ConfigProblem.InvalidCondition });
  // }

  // if (!isValidEbayBuyingFormat(apiParams?.buying_format as string)) {
  //   problems.push({ problem: ConfigProblem.InvalidBuyingFormat });
  // }

  // if (!isValidEbayShowOnly(apiParams?.show_only as string)) {
  //   problems.push({ problem: ConfigProblem.InvalidShowOnly });
  // }

  // if (!isValidEbaySortBy(apiParams?.sort_by as string)) {
  //   problems.push({ problem: ConfigProblem.InvalidSortBy });
  // }

  return problems;
}

const validateRedfinProjectAPIParams = (projectConfig: CollectorConfig, settingsFromApi: SettingsFromApi): ConfigProblemWithMessage[] => {
  const problems = [];
  const apiParams = projectConfig.apiParams;
  if (!isValidRedfinTLD(apiParams?.tld as string)) {
    problems.push({ problem: ConfigProblem.InvalidTLD });
  }
  // Note: Don't validate the URL here. That's not an API parameter.

  return problems;
}

export const validateApiParams = (projectConfig: CollectorConfig, settingsFromApi: SettingsFromApi): ConfigProblemWithMessage[] => {

  // Generic part
  const problems = [];
  if (!isValidNum(projectConfig.apiParams?.maxCost as number)) {
    problems.push({ problem: ConfigProblem.InvalidMaxCost });
  }

  if (!isValidNum(projectConfig.apiParams?.sessionNumber as number)) {
    problems.push({ problem: ConfigProblem.InvalidSessionNumber });
  }

  // Project specific part
  const specificProblems = (() => {
    if (isAsyncUrlsProject(projectConfig.type)) {
      return validateAsyncURLsAPIParams(projectConfig, settingsFromApi);
    } else if (isAmazonProject(projectConfig.type)) {
      return validateAmazonProjectAPIParams(projectConfig, settingsFromApi);
    } else if (isGoogleProject(projectConfig.type)) {
      return validateGoogleProjectAPIParams(projectConfig, settingsFromApi);
    } else if (isWalmartProject(projectConfig.type)) {
      return validateWalmartProjectAPIParams(projectConfig, settingsFromApi);
    } else if (isEbayProject(projectConfig.type)) {
      return validateEbayProjectAPIParams(projectConfig, settingsFromApi);
    } else if (isRedfinProject(projectConfig.type)) {
      return validateRedfinProjectAPIParams(projectConfig, settingsFromApi);
    } else {
      return [];
    }
  })();

  return [...problems, ...specificProblems];

}

export const validateInput = (projectType: CollectorType, inputConfig: InputConnectorConfig): ConfigProblemWithMessage[] => {
  if (!inputConfig) {
    return [{ problem: ConfigProblem.InvalidOutput }];
  }
  switch (inputConfig.type) {
    case 'json_url': {
      return isValidUrl((inputConfig as JsonUrlInputConfig).url) ? [] : [{ problem: ConfigProblem.InvalidInput }];
    }
    case 'list_literal': {
      const validatorFn = inputValidatorFn(projectType);
      const allElements = (inputConfig as ListLiteralInputConfig).list
        .split('\n')
        .map((element) => element.trim())
        .filter(element => element);
      const invalidElements = allElements
        .filter((el) => !validatorFn(el));

      const { duplicates } = allElements.reduce((acc, act) => {
        const { duplicates, elementsSet } = acc;
        if (elementsSet.has(act)) {
          return { duplicates: [...duplicates, act], elementsSet };
        } else {
          elementsSet.add(act);
          return { duplicates, elementsSet };
        }
      }, { duplicates: [] as string[], elementsSet: new Set() });

      // Empty
      if (allElements.length === 0) return [
        { problem: ConfigProblem.InvalidInput },
        { problem: ConfigProblem.EmptyLiteralList }
      ];
      else if (projectType === 'async_urls' && allElements.some(e => e.includes(' '))) return [
        { problem: ConfigProblem.InvalidInput },
        { problem: ConfigProblem.InvalidLiteralList, message: 'Please check if all white spaces are removed.' }
      ];
      else if (!validateCommaInputs(projectType, allElements)) return [
        { problem: ConfigProblem.InvalidInput },
        { problem: ConfigProblem.InvalidLiteralList, message: `Please enter each URL, Asin or keyword in one line.` }
      ];
      else if (invalidElements.length > 0) return [
        { problem: ConfigProblem.InvalidInput },
        { problem: ConfigProblem.InvalidLiteralList, message: `Invalid elements: ${invalidElements.join(', ')}` }
      ];
      else if (duplicates.length > 0) return [
        { problem: ConfigProblem.InvalidInput },
        { problem: ConfigProblem.DuplicatesInListLiteral, message: `Duplicate elements: ${duplicates.join(', ')}` }
      ];
      else
        return [];
    }

    case 'webhook_input': {
      const problems = isValidUrl((inputConfig as WebhookInputConfig).url) ? [] : [
        { problem: ConfigProblem.InvalidInput },
        { problem: ConfigProblem.InvalidWebhookURL }
      ];
      return problems;
    }
    case 'upload_csv_list':
    case 'upload_json_list': {
      return (inputConfig as UploadJsonListConfig | UploadCsvListConfig).inputKey ? [] : [{ problem: ConfigProblem.InvalidInput }, { problem: ConfigProblem.MissingUploadedFile }];
    }
  }
}

export const validateOutput = (outputConfig?: OutputConfig): ConfigProblemWithMessage[] => {
  if (!outputConfig) {
    return [{ problem: ConfigProblem.InvalidOutput }];
  }
  switch (outputConfig.type) {
    case 'webhook':
      const webhookURL = (outputConfig as WebhookOutputConfig).url;
      if (!webhookURL) {
        return [
          { problem: ConfigProblem.InvalidOutput },
          { problem: ConfigProblem.MissingWebhookURL },
        ];
      } else {
        return isValidUrl(webhookURL) ? [] : [
          { problem: ConfigProblem.InvalidOutput, },
          { problem: ConfigProblem.InvalidWebhookURL },
        ];
      }

    case 'devnull':
    case 'save':
      return [];
  }
}

export const validateProject = (proj: NewProjectConfig | ProjectConfig, settings: SettingsFromApi): { valid: boolean, problems: ConfigProblemWithMessage[] } => {
  const problems: ConfigProblemWithMessage[] = [];

  problems.push(...validateInput(proj.config.type, proj.input));
  problems.push(...validateOutput(proj.output));
  problems.push(...validateApiParams(proj.config, settings));
  const { valid: validCron } = isValidCron(proj.cron);
  if (!validCron) {
    problems.push({ problem: ConfigProblem.InvalidCron });
  }

  return {
    valid: problems.length === 0,
    problems
  };
}
