import { createContext, Suspense, useContext } from "react";
import { Await, defer, Outlet, useAsyncError, useRouteLoaderData } from "react-router-dom";

import scraperApi from "api";

import GlobalLoadingIndicator from "components/GlobalLoadingIndicator";

import { UserType } from "providers/UserProvider";

import NotFound from "routes/NotFound";


const UserContext = createContext<UserType | null>(null);

export const ID = "user-data";

export function loader({ request }: { request: Request }) {
  // TODO the "defer" API is deprecated, so we should return simple promises, but that makes the <Suspense>
  //  fallback kick in in case of revalidation
  return defer({
    userPromise: scraperApi.auth.me({ signal: request.signal })
  });
}

function ErrorHandler() {
  const error = useAsyncError() as any;

  if ([
    "user_cannot_load_data",
    "user_not_found"
  ].includes(error?.error_code)
  ) {
    return (
      <UserContext.Provider value={ null }>
        <Outlet/>
      </UserContext.Provider>
    );
  }

  throw error;
}

export function Component() {
  const loaderData = useRouteLoaderData(ID) as { userPromise: Promise<UserType> };

  // TODO using <Suspense>-<Await> here will not work in future React versions as the "defer" API is deprecated and will be removed completely
  //  without that, the fallback element will be rendered again while the data is being reloaded and the promise is awaited
  //  useNavigation should help here, there are nice state check examples in the react router docs:
  //  https://reactrouter.com/6.29.0/hooks/use-navigation#navigationstate
  return (
    <Suspense fallback={ <GlobalLoadingIndicator/> }>
      <Await
        resolve={ loaderData.userPromise }
        errorElement={ <ErrorHandler/> }
      >
        { (user) => (
          <UserContext.Provider value={ user }>
            <Outlet/>
          </UserContext.Provider>
        ) }
      </Await>
    </Suspense>
  );
}

export function ErrorBoundary() {
  return <NotFound />;
}

export function useUser() {
  return useContext(UserContext);
}
