import { UserType } from "providers/UserProvider";
import {
  FeatureSwitchCheck,
  UserInList,
  UserSignedUpAfter
} from "./checks";


type FeatureSwitchCheckConstructor<C> = new (featureSwitch: FeatureSwitch) => FeatureSwitchCheck<C>;

export type FeatureSwitch =
  "REACT_APP_CIO_IN_APP_MESSAGES_USERS" |
  "REACT_APP_ANNUAL_PLANS_USERS" |
  "REACT_APP_ANNUAL_PLANS_NEW_SIGNUPS_AFTER" |
  "REACT_APP_API_PLAYGROUND_USERS" |
  "REACT_APP_CANCELLATION_SURVEY_USERS" |
  "REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS" |
  "REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS" |
  "REACT_APP_NEW_DASHBOARD_DESIGN_PILOT_USERS" |
  "REACT_APP_NEW_DASHBOARD_DESIGN_NEW_SIGNUPS_AFTER" |
  "REACT_APP_PROFESSIONAL_PLAN_USERS" |
  "REACT_APP_NEW_BILLING_PAGE_PILOT_USERS";

const featureSwitches = new Map<FeatureSwitch, FeatureSwitchCheckConstructor<unknown>>([
  [ "REACT_APP_CIO_IN_APP_MESSAGES_USERS", UserInList ],
  [ "REACT_APP_ANNUAL_PLANS_USERS", UserInList ],
  [ "REACT_APP_ANNUAL_PLANS_NEW_SIGNUPS_AFTER", UserSignedUpAfter ],
  [ "REACT_APP_API_PLAYGROUND_USERS", UserInList ],
  [ "REACT_APP_CANCELLATION_SURVEY_USERS", UserInList ],
  [ "REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS", UserInList ],
  [ "REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS", UserInList ],
  [ "REACT_APP_NEW_DASHBOARD_DESIGN_PILOT_USERS", UserInList ],
  [ "REACT_APP_NEW_DASHBOARD_DESIGN_NEW_SIGNUPS_AFTER", UserSignedUpAfter ],
  [ "REACT_APP_PROFESSIONAL_PLAN_USERS", UserInList ],
  [ "REACT_APP_NEW_BILLING_PAGE_PILOT_USERS", UserInList ],
]);

export function isFeatureAvailable(featureSwitch: FeatureSwitch | undefined, user: UserType | undefined | null) {
  if (!user) {
    return false;
  }
  if (!featureSwitch) {
    return true;
  }

  const checker = new (featureSwitches.get(featureSwitch)!)(featureSwitch);

  return checker.check(user);
}
