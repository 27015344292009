import { PricingPlanProps } from "v2/components/billing/PricingPlan";


export default function loadingFakePlanProps(includeNewProfessionalPlan?: boolean): { monthlyProps: PricingPlanProps }[] {
  const fakePlanProps: { monthlyProps: PricingPlanProps }[] = [
    {
      monthlyProps: {
        planSlug: "hobby_2",
        name: "Hobby",
        price: 4900,
        originalPrice: 4900,
        apiCreditLimit: 100000,
        apiConcurrencyLimit: 20,
        apiGeotargeting: "US & EU Geotargeting",
        period: 1,
        periodUnit: "month",
        theme: "secondary",
        buttonAction: "upgrade",
        buttonClassName: "button button-secondary",
        buttonDisabled: true,
        planAutoRenewal: undefined,
      }
    },

    {
      monthlyProps: {
        planSlug: "startup_2",
        name: "Startup",
        price: 14900,
        originalPrice: 14900,
        apiCreditLimit: 1000000,
        apiConcurrencyLimit: 50,
        apiGeotargeting: "US & EU Geotargeting",
        period: 1,
        periodUnit: "month",
        theme: "secondary",
        buttonAction: "upgrade",
        buttonClassName: "button button-secondary",
        buttonDisabled: true,
        planAutoRenewal: undefined,
      }
    },

    {
      monthlyProps: {
        planSlug: "business_2",
        name: "Business",
        price: 29900,
        originalPrice: 29900,
        apiCreditLimit: 3000000,
        apiConcurrencyLimit: 100,
        apiGeotargeting: "All Geotargeting",
        period: 1,
        periodUnit: "month",
        theme: "secondary",
        buttonAction: "upgrade",
        buttonClassName: "button button-secondary",
        buttonDisabled: true,
        planAutoRenewal: undefined,
      }
    }
  ];

  if (includeNewProfessionalPlan) {
    fakePlanProps.push({
      monthlyProps: {
        planSlug: "professional_202503",
        name: "Scaling",
        price: 47500,
        originalPrice: 47500,
        apiCreditLimit: 5000000,
        apiConcurrencyLimit: 200,
        apiGeotargeting: "All Geotargeting",
        period: 1,
        periodUnit: "month",
        theme: "secondary",
        buttonAction: "upgrade",
        buttonClassName: "button button-secondary",
        buttonDisabled: true,
        planAutoRenewal: undefined,
      }
    });
  }

  fakePlanProps.push({
    monthlyProps: {
      name: "Enterprise",
      price: undefined,
      originalPrice: undefined,
      apiCreditLimit: undefined,
      apiConcurrencyLimit: undefined,
      apiGeotargeting: undefined,
      planSlug: "enterprise",
      period: 1,
      periodUnit: "month",
      theme: "secondary",
      buttonAction: "upgrade",
      buttonClassName: "button button-secondary",
      buttonDisabled: true,
      planAutoRenewal: undefined,
    }
  });

  return fakePlanProps;
};
