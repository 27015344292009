import IssuesBanner from "components/IssuesBanner";
import NewBillingPageBanner from "./NewBillingPageBanner";
import NewDashboardDesignAvailableBanner from "./NewDashboardDesignAvailableBanner";


export default function TopBanner() {
  return (
    <div className="flex flex-col">
      <NewDashboardDesignAvailableBanner />
      <NewBillingPageBanner />
      <IssuesBanner />
    </div>
  );
};
