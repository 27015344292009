import { ForwardedRef, forwardRef, ReactNode } from "react";
import { Link, useLocation, useNavigation } from "react-router-dom";
import { MenuItem } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { cx, fmtNumber } from "utils";
import { isEnterprise } from "utils/planUtils";

import { StatefulComponentThemeProps, Theme, Themes } from "styles/themes";

import { useContactSales } from "hooks/useContactSales";

import Button from "components/Button";
import CheckedComponent from "components/CheckedComponent";
import Menu, { MenuButton, MenuItems } from "components/Menu";
import PriceTag from "components/PriceTag";
import Spinner from "components/Spinner";
import { PureTooltip } from "components/Tooltip";

import { ApiGeotargeting } from "types/ApiGeotargeting";
import { BillingPeriodUnit } from "types/BillingPeriodUnit";

import { PlanActions } from "types/billing/PlanActions";


export interface PricingPlanProps {
  name: string;
  price: number | undefined;
  originalPrice?: number;
  apiCreditLimit: number | undefined;
  apiConcurrencyLimit: number | undefined;
  apiGeotargeting: ApiGeotargeting | undefined;
  period?: number;
  periodUnit?: BillingPeriodUnit;
  isAnnual?: boolean;
  planSlug: string;
  theme: Theme;
  bannerLabel?: string;
  buttonAction: PlanActions;
  buttonClassName?: string;
  buttonDisabled?: boolean;
  buttonTooltip?: JSX.Element | ReactNode | string;
  buttonTooltipClickable?: boolean;

  planAutoRenewal: number | undefined;

  maxCreditsOfAvailablePlans?: number;
}

interface PricingPlanThemeType extends StatefulComponentThemeProps {
  bannerFill: string;
  bannerTextColor: string;
  borderColor: string;
}

const PricingPlanThemes: Themes<PricingPlanThemeType> = {
  primary: {
    default: {
      base: "",
      disabled: "bg-lightGray dark:bg-neutral-500 text-black border-borderColor dark:border-neutral-200",
      bannerFill: "bg-brandPrimary dark:bg-primary-600",
      bannerTextColor: "text-white",
      borderColor: "border-brandPrimary dark:border-primary-600"
    },
    destructive: {
      base: "",
      bannerFill: "bg-red-600 dark:bg-error-600",
      bannerTextColor: "text-white",
      borderColor: "border-red-600 dark:border-error-600"
    },
  },
  secondary: {
    default: {
      base: "",
      bannerFill: "bg-lightGray dark:bg-neutral-500",
      bannerTextColor: "text-white",
      borderColor: "border-borderColor dark:border-neutral-200"
    },
  },
  warning: {
    default: {
      base: "",
      bannerFill: "bg-warning-600",
      bannerTextColor: "text-white",
      borderColor: "border-warning-600"
    }
  },
  // disabled: {
  //   default: {
  //     base: "",
  //     bannerFill: "bg-neutral-500",
  //     bannerTextColor: "text-black",
  //     borderColor: "border-neutral-200"
  //   }
  // }
};

// export type CustomPlanOverrideType = {
//   cf_api_auto_parsers?: string;
//   cf_api_concurrency_limit?: number;
//   cf_api_geotargeting?: string;
//   cf_api_overage?: string;
//   cf_api_premium_proxies?: string;
//   cf_api_rendering?: string;
//   cf_api_request_limit?: number;
// };


function ComponentWithTooltip(
  {
    tooltipContent,
    tooltipClickable,
    className,
    children
  }: {
    tooltipContent: string | JSX.Element | ReactNode | undefined;
    tooltipClickable?: boolean;
    className?: string;
    children: JSX.Element | ReactNode;
  }
) {
  if (tooltipContent) {
    return (
      <PureTooltip content={ tooltipContent } className={ className } clickable={ tooltipClickable }>
        { children }
      </PureTooltip>
    );
  } else {
    return <div className={ className }>{ children }</div>;
  }
}


const PricingPlan = forwardRef(
  ({
     name,
     price,
     originalPrice,
     apiCreditLimit,
     apiConcurrencyLimit,
     apiGeotargeting,
     period = 1,
     periodUnit = "month",
     isAnnual,
     planSlug,
     theme,
     bannerLabel,
     buttonAction,
     buttonClassName,
     buttonDisabled,
     buttonTooltip,
     buttonTooltipClickable,
     planAutoRenewal,
     maxCreditsOfAvailablePlans,
   }: PricingPlanProps,
   ref: ForwardedRef<HTMLDivElement>
  ) => {
    const location = useLocation();
    const navigation = useNavigation();

    const wasThisButtonPressed = navigation.location?.pathname?.endsWith(planSlug);
    const wasAutoRenewalButtonPressed = navigation.location?.pathname?.includes("/auto-renew/");

    // const features = PlanFeatures(planSlug, customPlanOverride, maxCreditsOfAvailablePlans);

    const cardTheme = PricingPlanThemes[theme] || PricingPlanThemes['secondary'];

    const buttonProps = {
      className: buttonClassName || "button button-secondary",
      fullWidth: true,
      centerAlign: true,
      icon: wasThisButtonPressed ? {
        Icon: Spinner,
        absolute: true
      } : undefined,
      disabled: buttonDisabled || (navigation.state !== "idle") // TODO also check if billing related fetchers are idle or not
    };

    // const checkBillingAddressInNewDialog = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");
    // const checkPaymentSourcesInNewDialog = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");
    const { contactSalesFn } = useContactSales();


    return (
      <div
        className="flex flex-col h-full w-full"
        ref={ ref }
      >
        <div
          className={ cx(!bannerLabel && "invisible", cardTheme?.default?.bannerFill, cardTheme?.default?.bannerTextColor, "text-center text-xs py-1") }>
          { bannerLabel || "placeholder" }
        </div>

        <div className={ cx(cardTheme?.default?.borderColor, "flex flex-col h-full px-5 py-6 border") }>
          <div className="text-sm uppercase text-brandDarkest dark:text-primary-800 font-medium">
            { name }
          </div>

          <div className={ "pt-1 pb-5 border-b border-borderColor dark:border-neutral-200" }>
            <PriceTag
              price={ price || "Custom" }
              originalPrice={ originalPrice }
              hasDiscount={ Boolean(price && originalPrice && (price !== originalPrice)) }
              period={ period }
              periodUnit={ periodUnit }
              isAnnual={ isAnnual || false }
            />
          </div>

          <ul className="flex flex-col pt-5 pb-6 space-y-4 text-xs">
            { (isEnterprise(planSlug) && !apiCreditLimit) ? (
              <li className="flex items-center">
                <span className="mx-2 text-justify">Need more than {fmtNumber(maxCreditsOfAvailablePlans)} API Credits with all premium features and dedicated support?</span>
              </li>
            ) : (
              <>
                <li className="flex items-center">
                  <CheckedComponent><span className="font-bold">{ fmtNumber(apiCreditLimit) }</span> API Credits</CheckedComponent>
                </li>
                <li className="flex items-center">
                  <CheckedComponent><span className="font-bold">{ fmtNumber(apiConcurrencyLimit) }</span> Concurrent Threads</CheckedComponent>
                </li>
                <li className="flex items-center">
                  <CheckedComponent>{ apiGeotargeting }</CheckedComponent>
                </li>
              </>
            ) }
          </ul>

          <div className="flex items-end mt-4 h-full">
            { buttonAction === "cancel_change" && (
              <Button
                { ...buttonProps }
                text="Cancel Change"
                href="plans/cancel-scheduled-change"
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "contact_sales" && (
              <Button
                { ...buttonProps }
                text="Contact Sales"
                onClick={ contactSalesFn }
              />
            ) }

            { buttonAction === "downgrade" && (
              <Button
                { ...buttonProps }
                text="Downgrade"
                href={ `plans/downgrade/${ planSlug }` }
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "downgrade_annual" && (
              <Button
                { ...buttonProps }
                text="Downgrade"
                href={ `plans/downgrade/${ planSlug }` }
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "downgrade_enterprise" && (
              <Button
                { ...buttonProps }
                text="Downgrade"
                href="plans/cancel-enterprise"
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "pay_now" && (
              <Button
                { ...buttonProps }
                text="Pay Now"
                href="invoices/pay-now"
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "renew" && (
              <ComponentWithTooltip tooltipContent={ buttonTooltip } tooltipClickable={ buttonTooltipClickable } className="relative w-full flex items-stretch flex-shrink-0 justify-center">
                <Button
                  { ...buttonProps }
                  text="Renew Now"
                  href={ `plans/renew/${ planSlug }` }
                  shouldPassBackgroundLocation
                  icon={ (wasThisButtonPressed || wasAutoRenewalButtonPressed) ? {
                    Icon: Spinner,
                    absolute: true
                  } : undefined }
                />
                { buttonProps.disabled && (
                  <div className="button button-primary disabled flex items-center px-3 !border-l-0">
                    <ChevronDownIcon
                      className="w-5 h-5 text-gray dark:text-neutral-600"
                      aria-hidden="true"
                    />
                  </div>
                ) }
                { !buttonProps.disabled && (
                  <Menu>
                    <MenuButton>
                      <div className="button button-primary h-full px-3 flex items-center">
                        <ChevronDownIcon
                          className="w-5 h-5 text-white group-hover:text-white"
                          aria-hidden="true"
                        />
                      </div>
                    </MenuButton>
                    <MenuItems
                      anchor={ { to: "bottom end", padding: "1rem", gap: "0.5rem" } }
                    >
                      <MenuItem>
                        <Link
                          to={ `plans/renew/${ planSlug }` }
                          state={ { backgroundLocation: location } }
                          className="group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                          viewTransition
                        >
                          Renew now
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to="plans/auto-renew/95"
                          state={ { backgroundLocation: location } }
                          className={ cx(
                            (planAutoRenewal === 95) &&
                            "!bg-brandPrimary !dark:bg-primary-600 !text-white pointer-events-none",
                            "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                          ) }
                          viewTransition
                        >
                          Renew automatically at 95% usage
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to="plans/auto-renew/100"
                          state={ { backgroundLocation: location } }
                          className={ cx(
                            (planAutoRenewal === 100) &&
                            "!bg-brandPrimary !dark:bg-primary-600 !text-white pointer-events-none",
                            "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                          ) }
                          viewTransition
                        >
                          Renew automatically at 100% usage
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to="plans/auto-renew/clear"
                          state={ { backgroundLocation: location } }
                          className={ cx(
                            (planAutoRenewal === null) &&
                            "!bg-brandPrimary !dark:bg-primary-600 !text-white pointer-events-none",
                            "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                          ) }
                          viewTransition
                        >
                          Standard renewal cycle
                        </Link>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                ) }
              </ComponentWithTooltip>
            ) }

            { buttonAction === "upgrade" && (
              <Button
                { ...buttonProps }
                text="Upgrade"
                href={ `plans/upgrade/${ planSlug }` }
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "upgrade_annual" && (
              <Button
                { ...buttonProps }
                text="Upgrade"
                href={ `plans/upgrade/${ planSlug }` }
                shouldPassBackgroundLocation
              />
            ) }

          </div>
        </div>
      </div>
    );
  });

export default PricingPlan;
