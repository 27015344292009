import { useCallback, useEffect, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";
import _ from "lodash";

import { ReactComponent as AnnualArrowIcon } from "assets/icons/annual_discount_arrow.svg";

import Button from "components/Button";
import { useFeatureSwitch } from "components/FeatureSwitch";
import PricingPlan, { PricingPlanProps } from "v2/components/billing/PricingPlan";
import { PlanAttributes } from "v2/components/billing/PricingPlan/planFeatures";
import TaggedText from "components/TaggedText";

import { useActiveCoupons } from "v2/hooks/billing/useActiveCoupons";
import { useActiveSubscription } from "v2/hooks/billing/useActiveSubscription";
import { usePlans } from "v2/hooks/billing/usePlans";

import Messages from "misc/Messages";

import { useUser } from "routes/dataroutes/UserData";

import Plan, { PlanPair } from "types/Plan";

import { cx, fmtDate } from "utils";
import {
  getDiscountPriceFor,
  getPriceMultiplier,
  hasToContactUsBeforeChange,
  isAnnualPlan,
  isEnterprise,
  isUsingChargebeeCustomFields
} from "utils/planUtils";

import loadingFakePlanProps from "./loadingFakePlanProps";
import { FixAnnualDiscountCoupon } from "./FixAnnualDiscountCoupon";


interface PlanPairPricingProps {
  monthlyProps: PricingPlanProps,
  annualProps?: PricingPlanProps
}

export type ShowingMonthlyOrAnnual = (keyof PlanPair) | "transitioning";


function RenderCardsList(
  {
    planPairsToShow,
    showingPrices,
    setShowingPrices,
    isLoading
  }: {
    planPairsToShow: PlanPairPricingProps[];
    showingPrices: ShowingMonthlyOrAnnual;
    setShowingPrices: (showingPrices: ShowingMonthlyOrAnnual) => void;
    isLoading?: boolean;
  }
) {
  const user = useUser()!;

  const showAnnualUserInList = useFeatureSwitch("REACT_APP_ANNUAL_PLANS_USERS");
  const showAnnualNewUser = useFeatureSwitch("REACT_APP_ANNUAL_PLANS_NEW_SIGNUPS_AFTER");
  const showAnnualPrices = isAnnualPlan(user.planSlug) || showAnnualUserInList || showAnnualNewUser;

  return (
    <div className="grid lg:grid-cols-[repeat(auto-fit,minmax(250px,1fr))] auto-rows-[1fr] gap-4">
      {
        planPairsToShow.map((planPair) => {
          const transitionClassName = cx(
            "transition",
            "data-[closed]:opacity-0",
            "data-[enter]:duration-500 data-[enter]:data-[closed]:scale-y-50",
            "data-[leave]:duration-300 data-[leave]:data-[closed]:scale-y-[.2]"
          );

          return (
            <div
              key={ planPair.monthlyProps.planSlug }
              className={ cx("min-w-[250px] min-h-[385px] h-full", isLoading && "animate-pulse blur-[7px]") }
            >
              { (showAnnualPrices && planPair.annualProps) && (
                <>
                  <Transition
                    show={ showingPrices === "monthly" }
                    afterLeave={ () => setShowingPrices("annual") }
                  >
                    <div className={ transitionClassName }>
                      <PricingPlan { ...planPair.monthlyProps } />
                    </div>
                  </Transition>
                  <Transition
                    show={ showingPrices === "annual" }
                    afterLeave={ () => setShowingPrices("monthly") }
                  >
                    <div className={ transitionClassName }>
                      <PricingPlan { ...planPair.annualProps! } />
                    </div>
                  </Transition>
                </>
              ) }

              { (!showAnnualPrices || !planPair.annualProps) && (
                <PricingPlan { ...planPair.monthlyProps } />
              ) }
            </div>
          );
        })
      }
    </div>
  );
}

export default function PlanCardsList() {

  const user = useUser()!;
  const { activeSubscription } = useActiveSubscription();
  const { planPairs } = usePlans();
  const { activeCoupons } = useActiveCoupons();

  const myPlanSlug = user.planSlug;
  const canHaveAffiliatePlan = user.canHaveAffiliatePlan;
  const canHaveProfessionalPlan = useFeatureSwitch("REACT_APP_PROFESSIONAL_PLAN_USERS");

  const findPlanPair = useCallback((planSlug: string) => {
    return planPairs?.find(pp => pp.monthly.planSlug === planSlug);
  }, [ planPairs ]);

  const plansToShow = useMemo((): PlanPair[] => {
    const planPairsToShow: (PlanPair | undefined)[] = [];

    let enterpriseCardAdded = false;

    if (myPlanSlug === "student_npo") {
      planPairsToShow.push(findPlanPair("student_npo"));
    }

    if (myPlanSlug === "affiliate" || canHaveAffiliatePlan) {
      planPairsToShow.push(findPlanPair("affiliate"));
    }

    if ((myPlanSlug === "hobby") || (myPlanSlug === "hobby_1")) {
      planPairsToShow.push(findPlanPair("hobby_1"));
    } else {
      planPairsToShow.push(findPlanPair("hobby_2"));
    }

    if (myPlanSlug === "startup") {
      planPairsToShow.push(findPlanPair("startup"));
    } else {
      planPairsToShow.push(findPlanPair("startup_2"));
    }

    planPairsToShow.push(findPlanPair("business_2"));

    if ((myPlanSlug === "professional_1") || (myPlanSlug === "annual_professional_1")) {
      planPairsToShow.push(findPlanPair("professional_1"));
    } else if (canHaveProfessionalPlan || ((myPlanSlug === "professional_202503") || (myPlanSlug === "annual_professional_202503"))) {
      planPairsToShow.push(findPlanPair("professional_202503"));
    }

    if (myPlanSlug && (myPlanSlug === activeSubscription?.planSlug) && (isUsingChargebeeCustomFields(myPlanSlug))) {
      planPairsToShow.push({
        monthly: activeSubscription
      });
      enterpriseCardAdded = true;
    }

    if (activeSubscription?.scheduledSubscription && (activeSubscription.scheduledSubscription.planSlug !== myPlanSlug) && isUsingChargebeeCustomFields(activeSubscription.scheduledSubscription.planSlug)) {
      planPairsToShow.push({
        monthly: activeSubscription.scheduledSubscription
      });
      enterpriseCardAdded = true;
    }

    if (!enterpriseCardAdded && planPairs) {
      planPairsToShow.push(findPlanPair("enterprise"));
    }

    const compactedPlanPairs = _.compact(planPairsToShow);

    // showing annual discounts by setting the original price to 12x the monthly price
    // annual cards will have a default hardcoded -10% coupon to show the default discount compared to their monthly pairs
    for (const planPair of compactedPlanPairs) {
      if (planPair.monthly && planPair.annual) {
        const multiplier = getPriceMultiplier(planPair.monthly.billingPeriod || 1, planPair.monthly.billingPeriodUnit || "month", planPair.annual.billingPeriod || 1, planPair.annual.billingPeriodUnit || "month");
        planPair.annual.price = (planPair.monthly.price! * multiplier);
      }
    }

    return compactedPlanPairs;
  }, [ myPlanSlug, activeSubscription, findPlanPair, planPairs, canHaveAffiliatePlan, canHaveProfessionalPlan ]);

  const getPlan = useCallback((planSlug: string | undefined) => {
    if (planSlug) {
      const planPair = plansToShow.find(planPair => (planPair.monthly.planSlug === planSlug) || (planPair.annual?.planSlug === planSlug));
      if (planPair) {
        return planPair.monthly.planSlug === planSlug ? planPair.monthly : planPair.annual;
      }
    }

    return undefined;
  }, [ plansToShow ]);

  const maxCreditsOfAvailablePlans = useMemo(() => {
    return _.max(plansToShow?.map(pp => {
      const planAttributes = pp.monthly ? PlanAttributes[pp.monthly.planSlug] : undefined;
      return planAttributes?.credits || 0;
    }) || [ 0 ]);
  }, [ plansToShow ]);

  const getPlanIdx = useCallback((planSlug: string | undefined) => {
    const idx = planSlug ? (plansToShow.findIndex(planPair => ((planPair.monthly.planSlug === planSlug) || (planPair.annual?.planSlug === planSlug))) + 1) : -1;
    return idx * (isAnnualPlan(planSlug) ? 100 : 1);
  }, [ plansToShow ]);

  const myPlan = useMemo(() => {
    return getPlan(user.planSlug);
  }, [ user, getPlan ]);

  const myPlanIdx = useMemo(() => {
    return getPlanIdx(myPlan?.planSlug);
  }, [ getPlanIdx, myPlan ]);

  const getPricingPlanPropsFor = useCallback((plan: Plan) => {
    const outOfSync = (myPlan && activeSubscription && (myPlan.planSlug !== activeSubscription.planSlug)) || false;
    const renewing = Boolean(activeSubscription?.lastRenewalAttempt);
    const scheduledCancellation = activeSubscription?.scheduledSubscription?.planSlug === "free";
    const pendingChanges = outOfSync || renewing;

    // const planIdx = getPlanIdx(plan.id);

    const props: PricingPlanProps = {
      planSlug: plan.planSlug,
      name: plan.planName,
      price: getDiscountPriceFor(plan, isAnnualPlan(plan.planSlug) ? [ ...(activeCoupons || []), FixAnnualDiscountCoupon ] : activeCoupons),
      originalPrice: plan.price,
      apiCreditLimit: plan.apiCreditLimit,
      apiConcurrencyLimit: plan.apiConcurrencyLimit,
      apiGeotargeting: plan.apiGeotargeting,
      period: plan.billingPeriod,
      periodUnit: plan.billingPeriodUnit,
      isAnnual: isAnnualPlan(plan.planSlug),
      planAutoRenewal: user.planAutoRenewal,
      theme: "secondary",
      buttonAction: "contact_sales",
      buttonClassName: "button button-secondary",
      maxCreditsOfAvailablePlans,
    };

    if (plan.planSlug === myPlan?.planSlug) {
      // rendering the active plan (the one in our database)

      // user has unpaid invoices
      if (user.hasPaymentIssues) {
        props.buttonAction = "pay_now";
        props.buttonClassName = "button button-primary destructive";
        props.bannerLabel = "Outstanding invoices";

        return props;
      }

      props.buttonAction = "renew";

      if (!user.isRenewalAllowed) {
        props.buttonDisabled = true;
        props.buttonClassName = "button button-primary";
        props.buttonTooltip = <TaggedText message={ Messages.renewalDisabledTaggedMessage } />
        props.buttonTooltipClickable = true;
      } else if (scheduledCancellation) {
        props.theme = "warning";
        props.buttonClassName = "button button-primary";
        props.bannerLabel = "Cancelling on " + fmtDate(new Date((activeSubscription?.currentTermEnd || 0) * 1000));
      } else if (pendingChanges) {
        // pending changes (chargebee and database out of sync)
        // TODO props.theme = "warning";  -- we only have ERROR theme (destructive)

        if (renewing) {
          props.bannerLabel = "Renewing now";
        } else {
          const chargebeePlanIdx = getPlanIdx(activeSubscription?.planSlug);
          props.bannerLabel = (chargebeePlanIdx < 0 ? "Change" : ((myPlanIdx < chargebeePlanIdx) ? "Upgrade" : "Downgrade")) + " in progress";
        }
        props.buttonDisabled = true;
      } else {
        props.theme = "primary";
        props.buttonClassName = "button button-primary";
      }
    } else {
      // rendering a different plan

      const planIdx = getPlanIdx(plan.planSlug);
      if (plan.planSlug === activeSubscription?.scheduledSubscription?.planSlug) {
        const scheduledPlanIdx = getPlanIdx(activeSubscription.scheduledSubscription.planSlug);
        props.theme = "primary";
        if (isUsingChargebeeCustomFields(plan.planSlug)) {
          props.bannerLabel = "Scheduled upgrade";
        } else {
          props.buttonAction = "cancel_change";
          props.bannerLabel = "Scheduled " + ((scheduledPlanIdx < myPlanIdx) ? "downgrade" : "upgrade");
        }
      } else if (planIdx >= 0) {
        if (planIdx < myPlanIdx) {
          props.buttonAction = hasToContactUsBeforeChange(myPlan?.planSlug) ? "downgrade_enterprise" : (isAnnualPlan(activeSubscription?.planSlug) ? "downgrade_annual" : "downgrade");
        } else {
          props.buttonAction = hasToContactUsBeforeChange(plan?.planSlug) ? "contact_sales" : (isAnnualPlan(activeSubscription?.planSlug) ? "upgrade_annual" : "upgrade");
          if (isEnterprise(plan?.planSlug) && !isAnnualPlan(plan?.planSlug)) {
            props.bannerLabel = "SAVE 10% ON YEARLY PLAN";
          }
        }
      }

      if (
        user.hasPaymentIssues ||
        pendingChanges ||
        (user.isBlocked && (user.blockingCode !== "banned_from_free" || (![ "contact_sales", "upgrade", "upgrade_annual" ].includes(props.buttonAction))))
      ) {
        props.buttonDisabled = true;
      }
    }

    return props;
  }, [ myPlan, myPlanIdx, activeSubscription, activeCoupons, user, getPlanIdx, maxCreditsOfAvailablePlans ]);

  const showAnnualUserInList = useFeatureSwitch("REACT_APP_ANNUAL_PLANS_USERS");
  const showAnnualNewUser = useFeatureSwitch("REACT_APP_ANNUAL_PLANS_NEW_SIGNUPS_AFTER");
  const showAnnualPrices = isAnnualPlan(user.planSlug) || showAnnualUserInList || showAnnualNewUser;

  const monthlyOrAnnual = localStorage.getItem("monthlyOrAnnualBilling") as ShowingMonthlyOrAnnual || "monthly";
  const [ showingPrices, setShowingPrices ] = useState<ShowingMonthlyOrAnnual>(monthlyOrAnnual);
  const [ activeButton, setActiveButton ] = useState<ShowingMonthlyOrAnnual>(monthlyOrAnnual);

  useEffect(() => {
    localStorage.setItem("monthlyOrAnnualBilling", activeButton);
  }, [ activeButton ]);

  return (
    <>
      { showAnnualPrices && (
        <div className="flex flex-col gap-y-3">
          <div className="flex justify-end gap-x-1.5">
            <AnnualArrowIcon className="translate-y-2.5"/>
            <span className="text-sm text-gray dark:text-neutral-600">10% off</span>
          </div>
          <div className="flex justify-between">
            <span className="text-lg text-brandDarkest dark:text-primary-800">Plans & pricing</span>
            <div className="flex flex-row">
              <Button
                text="Monthly billing"
                className={ cx("button", activeButton === "monthly" ? "button-primary" : "button-secondary") }
                focusable={ false }
                onClick={ () => {
                  if (showingPrices === "annual") {
                    setActiveButton("monthly");
                    setShowingPrices("transitioning");
                  }
                } }
              />
              <Button
                text="Annual billing"
                className={ cx("button", activeButton === "annual" ? "button-primary" : "button-secondary") }
                focusable={ false }
                onClick={ () => {
                  if (showingPrices === "monthly") {
                    setActiveButton("annual");
                    setShowingPrices("transitioning");
                  }
                } }
              />
            </div>
          </div>
        </div>
      ) }
      <div className="grid lg:grid-cols-[repeat(auto-fit,minmax(250px,1fr))] auto-rows-[1fr] gap-4">
        { (activeSubscription && planPairs) && (
          <RenderCardsList
            planPairsToShow={ plansToShow.map(pp => {
              return {
                monthlyProps: getPricingPlanPropsFor(pp.monthly!),
                annualProps: pp.annual ? getPricingPlanPropsFor(pp.annual) : undefined
              };
            }) }
            showingPrices={ showingPrices }
            setShowingPrices={ setShowingPrices }
          />
        ) }
        { !(activeSubscription && planPairs) && (
          <RenderCardsList
            isLoading
            showingPrices={ showingPrices }
            setShowingPrices={ setShowingPrices }
            planPairsToShow={ loadingFakePlanProps(canHaveProfessionalPlan) }
          />
        ) }
      </div>
    </>
  );

};
