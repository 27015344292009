import _ from "lodash";

import { Coupon, CouponError } from "types/Coupons";
import { BillingPeriodUnit } from "types/BillingPeriodUnit";
import Plan from "types/Plan";
import { Plan as UserProviderPlan } from "providers/UserProvider";
import { fmtCurrency } from "./index";


export const canUsePremium = (planSlug: string | undefined) => {
  return planSlug !== "free" && planSlug !== undefined;
}

export const canUseUltraPremium = (planSlug: string | undefined) => {
  return planSlug !== "free" && planSlug !== undefined;
}

export const isEnterprise = (planSlug: string | undefined) => {
  return planSlug?.startsWith("enterprise");
};

export const manualRenewToThisIfScheduled = (planSlug: string | undefined) => {
  return !!(planSlug && (isEnterprise(planSlug) || [ "custom", "extended_trial" ].includes(planSlug)));
};

export const isUsingChargebeeCustomFields = (planSlug: string | undefined) => {
  return !!(planSlug && (planSlug.startsWith("enterprise") || [ "custom", "extended_trial" ].includes(planSlug)));
};

export const hasToContactUsBeforeChange = (planSlug: string | undefined) => {
  return !!(planSlug && (planSlug.startsWith("enterprise") || [ "custom", "extended_trial" ].includes(planSlug)));
};

export const isAnnualPlan = (planSlug: string | undefined) => {
  return planSlug?.startsWith("annual_");
};

export const getPriceMultiplier = (period1: number, periodUnit1: BillingPeriodUnit, period2: number, periodUnit2: BillingPeriodUnit) => {
  const periodMultipliers = {
    month: 1,
    year: 12
  };

  return (periodMultipliers[periodUnit2] * period2) / (periodMultipliers[periodUnit1] * period1);
};

export const applyCoupon = (plan: UserProviderPlan, coupon?: Coupon | CouponError) => {
  if (!coupon || _.has(coupon, "error")) {
    plan.discount_price = undefined;
  } else {
    const couponType = coupon as Coupon;
    if (couponType.discount_type === "percentage") {
      plan.discount_price = ((plan.price || 0) * (100 - (couponType.discount_percentage || 0))) / 100;
    } else if (couponType.discount_type === "fixed_amount") {
      plan.discount_price = Math.max((plan.price || 0) - (couponType.discount_amount || 0), 0);
      // TODO apply fix amount discount
    } else {
      // unsupported discount type, or empty coupon code
      plan.discount_price = undefined;
    }
  }

  return plan;
};

export const getDiscountPriceFor = (plan: Plan | undefined, coupons: Coupon[] | undefined) => {
  if (!plan) {
    return 0;
  }

  if (!coupons || (coupons.length === 0)) {
    return plan.price;
  }

  // use applicable coupons only
  const matchingCoupons = coupons.filter(c => !c.applicable_plan_ids || c.applicable_plan_ids.includes(plan.planSlug));

  // TODO Chargebee supports "line level" and "invoice level" coupons which can affect the calculation below, but we don't check such differences here
  //  if we want to deal with it, "line level" coupons should be applied first (both fix amount and percentage), followed by the invoice level coupons
  let discountPercentage = 0;
  let discountAmount = 0;

  for (const coupon of matchingCoupons) {
    if (coupon.discount_type === "percentage") {
      discountPercentage += coupon.discount_percentage || coupon.discount_value!;
    } else if (coupon.discount_type === "fixed_amount") {
      discountAmount += coupon.discount_amount || coupon.discount_value!;
    }
  }

  return ((plan.price - discountAmount) * (100 - discountPercentage)) / 100;
};

export const getCouponDiscountText = (coupon: Coupon) => {
  if (coupon.discount_type === "percentage") {
    return `-${ coupon.discount_value! }%`;
  } else if (coupon.discount_type === "fixed_amount") {
    return `-${ fmtCurrency(coupon.discount_value!) }`; // TODO should also pass the currency code of the coupon here
  }
};
