import { useLocation } from "react-router-dom";


const dataRoutes = [
  "/v2/billing"
];

export function useRelativeModalPathsForDataRoutes(path: string) {
  const location = useLocation();

  return dataRoutes.includes(location.pathname) ? (location.pathname + path) : path;
}
