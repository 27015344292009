import { useNewBillingPage } from "v2/hooks/pilot/useNewBillingPage";
import PilotFeatureAvailableBanner from "./PilotFeatureAvailableBanner";


export default function NewBillingPageBanner() {
  const { newBillingPageSetting, updateNewBillingPageSetting } = useNewBillingPage();

  return <PilotFeatureAvailableBanner
    text="Would you like to try and test an improved version of our Billing page?"
    setting={ newBillingPageSetting }
    updateSetting={ updateNewBillingPageSetting }
  />;
};
