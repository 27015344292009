import { FetcherWithComponents } from "react-router-dom";

import { useFetcherData } from "v2/hooks/useFetcherData";


type UnpaidInvoicesType = { count: number };

export function useUnpaidInvoices(): {
  unpaidInvoicesData?: { count: number },
  unpaidInvoicesFetcher: FetcherWithComponents<UnpaidInvoicesType>,
} {
  const { value, fetcher } = useFetcherData<UnpaidInvoicesType, UnpaidInvoicesType>({
    fetcherHref: "/billing-data/invoices/not-paid",
  });

  return {
    unpaidInvoicesData: value,
    unpaidInvoicesFetcher: fetcher!,
  };
}
