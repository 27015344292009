import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PilotFeatureSetting, usePilotFeature } from "./usePilotFeature";


export function useNewBillingPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    pilotFeatureSetting,
    updatePilotFeatureSetting
  } = usePilotFeature("saTryNewBillingPage", "REACT_APP_NEW_BILLING_PAGE_PILOT_USERS");

  const updateSetting = useCallback((newValue: PilotFeatureSetting) => {
    updatePilotFeatureSetting(newValue);

    if ((location.pathname === "/billing") && (newValue === "yes")) {
      navigate("/v2/billing", { replace: true });
    }
    if ((location.pathname === "/v2/billing") && (newValue !== "yes")) {
      navigate("/billing", { replace: true });
    }
  }, [ updatePilotFeatureSetting, navigate, location ]);

  return {
    newBillingPageSetting: pilotFeatureSetting,
    updateNewBillingPageSetting: updateSetting
  };
}
