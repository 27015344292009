import { useNavigation } from "react-router-dom";

import Spinner from "components/Spinner";
import StatefulLink from "components/StatefulLink";
import TaggedText from "components/TaggedText";
import { PureTooltip } from "components/Tooltip";

import { useActiveSubscription } from "v2/hooks/billing/useActiveSubscription";
import { useUnpaidInvoices } from "v2/hooks/billing/useUnpaidInvoices";

import Messages from "misc/Messages";

import { useUser } from "routes/dataroutes/UserData";

import { hasToContactUsBeforeChange } from "utils/planUtils";


export default function CancelSubscriptionLinks() {
  const navigation = useNavigation();

  const { activeSubscription: subscription } = useActiveSubscription();
  const user = useUser()!

  const { unpaidInvoicesData, unpaidInvoicesFetcher } = useUnpaidInvoices();

  const hasUnpaidInvoices = (unpaidInvoicesData?.count !== undefined) && (unpaidInvoicesData.count > 0);


  if (subscription?.scheduledSubscription?.planSlug === "free") {
    return (
      <StatefulLink
        to="plans/keep"
        addLocationToState
        className="cursor-pointer"
        hoverClassName="hover:underline"
        disabled={ user.isBlocked || (navigation.state !== "idle") }
      >
        Reactivate subscription
      </StatefulLink>
    );
  } else {
    return (
      <PureTooltip
        content={ hasUnpaidInvoices ? <TaggedText className="text-wrap" linkStyle="text-white hover:text-lightGray" message={ Messages.cannotCancelBecauseOfUnpaidInvoicesMessage } /> : undefined }
        clickable
      >
        <StatefulLink
          to={ hasToContactUsBeforeChange(subscription?.planSlug)
            ? "plans/cancel-enterprise"
            : "plans/cancel"
          }
          addLocationToState
          className="cursor-pointer"
          hoverClassName="hover:underline"
          disabled={
            user.hasPaymentIssues
            || user.isBlocked
            || (navigation.state !== "idle")
            || (unpaidInvoicesFetcher.state === "loading")
            || hasUnpaidInvoices
          }
        >
          <div className="flex flex-row gap-x-2">
            <p>Cancel subscription</p>
            { unpaidInvoicesFetcher.state !== "idle" && <Spinner theme="secondary" size={ 16 } className="!text-lightGray dark:text-neutral-200" /> }
          </div>
        </StatefulLink>
      </PureTooltip>
    );
  }
};
