import { ReactElement, Suspense, useMemo } from "react";
import { Await, useAsyncValue, useNavigation } from "react-router-dom";

import { ReactComponent as ScraperAPIIllustration3 } from "assets/images/scraperapi-illustration3.svg";

import { fmtDate, fmtNumber } from "utils";
import { isAnnualPlan, isEnterprise } from "utils/planUtils";

import { useChargebeePortals } from "providers/ChargebeePortalProvider";

import StatefulLink from "components/StatefulLink";
import TaggedText from "components/TaggedText";
import Spinner from "components/Spinner";

import { useActiveSubscription } from "v2/hooks/billing/useActiveSubscription";

import { useUser } from "routes/dataroutes/UserData";

import ActiveSubscription from "types/ActiveSubscription";

import CancelSubscriptionLinks from "./CancelSubscriptionLinks";


function SubscriptionStatus() {
  const user = useUser()!;
  const activeSubscription = useAsyncValue() as ActiveSubscription;

  const isAnnual = useMemo(() => isAnnualPlan(activeSubscription?.planSlug), [ activeSubscription?.planSlug ]);

  if (!!activeSubscription?.lastRenewalAttempt ||
    (user && activeSubscription && user.planSlug !== activeSubscription.planSlug)
  ) {
    return <>Your billing information is being updated.</>;
  }

  const addAutoRenewalAndPaymentIssuesMessages = (statusMessageParts: ReactElement[], auto_renewal: number | undefined, has_payment_issues: boolean) => {
    if (auto_renewal) {
      if (has_payment_issues) {
        statusMessageParts.push(<span key="payment issues">. The change will <b>not</b> happen automatically after reaching {auto_renewal}% of your credits because you have outstanding invoices.<br/></span>);
      } else {
        statusMessageParts.push(<span key="auto renewal"> or after reaching {auto_renewal}% of your credits.<br/></span>);
      }
    } else {
      statusMessageParts.push(<span key="no auto renewal">.<br/></span>);
    }
  };


  let statusMessageParts = [];

  const lastMonthOfAnnual = isAnnual && (activeSubscription.nextRenewalAt === activeSubscription.currentTermEnd);

  if (isAnnual) {

    statusMessageParts.push(<span key="annual reset">Your credits will be reset</span>);

    if (lastMonthOfAnnual) {
      statusMessageParts.push(<span key="annual last month"> and </span>);
    } else {
      statusMessageParts.push(<span key="annual reset date"> on {fmtDate(new Date(activeSubscription.nextRenewalAt * 1000))}</span>);
      addAutoRenewalAndPaymentIssuesMessages(statusMessageParts, user.planAutoRenewal, user.hasPaymentIssues);
    }
  }

  const subscription = isAnnual ? (lastMonthOfAnnual ? "your annual" : "Your annual") : "Your";

  if (activeSubscription?.scheduledSubscription) {
    if (activeSubscription.scheduledSubscription?.planSlug === "free") {
      statusMessageParts.push(<span key="cancellation">{subscription} subscription will {lastMonthOfAnnual ? "also" : ""} be cancelled on </span>);
    } else {
      statusMessageParts.push(<span key="scheduled change">{subscription} subscription is scheduled to change to the {activeSubscription.scheduledSubscription.planName} plan on </span>);
    }
  } else {
    statusMessageParts.push(<span key="annual renewal">{subscription} subscription will {lastMonthOfAnnual ? "also" : ""} be renewed on </span>);
  }
  statusMessageParts.push(<span key="renewal date">{fmtDate(new Date((activeSubscription?.currentTermEnd || 0) * 1000))}</span>);

  if (!isAnnual || lastMonthOfAnnual) {
    addAutoRenewalAndPaymentIssuesMessages(statusMessageParts, user.planAutoRenewal, user.hasPaymentIssues);
  } else {
    statusMessageParts.push(<span key="trailing dot">.</span>);
  }

  return <>{statusMessageParts}</>;
}

export default function SubscriptionManagementCard() {
  const navigation = useNavigation();

  const user = useUser()!;
  const { activeSubscriptionPromise, activeSubscription } = useActiveSubscription();
  const { openChargebeePortal } = useChargebeePortals();


  return (
    <div
      className="grid lg:grid-cols-lgSubscriptionCard px-12 py-6 bg-white border border-borderColor dark:border-neutral-200 gap-x-16 gap-y-8"
    >
      <div className="flex flex-col items-center md:flex-row gap-x-16 py-7">
        <ScraperAPIIllustration3 className="flex-shrink-0 h-40 rounded-md w-28"/>
        <div className="flex flex-col items-center justify-center space-y-2 text-center md:items-start md:text-left">
          {user.isBlocked && (
            <span className="text-lg text-brandDarkest dark:text-primary-800">
              <TaggedText message="Account blocked. [Contact support|contact_support]."/>
            </span>
          )}
          {!user.isBlocked && (
            <>
              <span className="text-lg text-brandDarkest dark:text-primary-800">
                You are currently on the <b>{user.planName}</b> plan
              </span>
              <div className="text-sm text-gray dark:text-neutral-600">
                <>
                  You have used {fmtNumber(user.requestCount)} of {fmtNumber(user.apiCallLimit)}{" "}
                  successful credits this month on the {user.planName} plan.
                  <br/>
                </>
                <Suspense fallback={ <Spinner size="XS" /> }>
                  <Await resolve={ activeSubscriptionPromise }>
                    <SubscriptionStatus />
                  </Await>
                </Suspense>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="flex flex-col h-full justify-center items-center lg:items-start pt-8 lg:pt-0 lg:pl-12 text-xs lg:text-left whitespace-nowrap gap-4 border-borderColor dark:border-neutral-200 border-t lg:border-t-0 lg:border-l"
      >
        <StatefulLink
          onClick={() => openChargebeePortal("ADDRESS")}
          hoverClassName="hover:cursor-pointer hover:underline"
          disabled={user.isBlocked && user.blockingCode !== "banned_from_free"}
        >
          Edit billing address
        </StatefulLink>
        <StatefulLink
          onClick={() => openChargebeePortal("PAYMENT_SOURCES")}
          hoverClassName="hover:cursor-pointer hover:underline"
          disabled={user.isBlocked && user.blockingCode !== "banned_from_free"}
        >
          Edit payment method
        </StatefulLink>
        <StatefulLink
          onClick={() => openChargebeePortal("BILLING_HISTORY")}
          hoverClassName="hover:cursor-pointer hover:underline"
          disabled={user.isBlocked}
        >
          View billing history
        </StatefulLink>
        <StatefulLink
          onClick={() => openChargebeePortal("SUBSCRIPTION_DETAILS")}
          hoverClassName="hover:cursor-pointer hover:underline"
          disabled={user.isBlocked}
        >
          Manage subscription
        </StatefulLink>
        { (user.canUseAllCoupons || user.canUseCoupons) && (
          <StatefulLink
            to="plans/coupons"
            addLocationToState
            className="flex gap-2"
            hoverClassName="hover:cursor-pointer hover:underline"
            disabled={ user.hasPaymentIssues || user.isBlocked || (navigation.state !== "idle") || !activeSubscription || isAnnualPlan(activeSubscription.planSlug) }
          >
            Manage coupons
            { (navigation.state !== "idle") && (navigation?.location?.pathname?.endsWith("plans/coupons")) && (
              <Spinner size="XXS" />
            ) }
          </StatefulLink>
        ) }
        { user.planSlug !== "free" && (
          <CancelSubscriptionLinks/>
        ) }
        { !isEnterprise(user.planSlug) && (
          <StatefulLink
            to="plans/delete"
            addLocationToState
            hoverClassName="hover:cursor-pointer hover:underline"
            disabled={ user.hasPaymentIssues || user.isBlocked || (navigation.state !== "idle") }
          >
            Request account deletion
          </StatefulLink>
        ) }
      </div>
    </div>
  );
};
