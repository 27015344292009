import { ChangeEvent, useCallback, useMemo } from "react";
import { FetcherWithComponents, useFetcher } from "react-router-dom";
import _ from "lodash";

import InputFieldWithValidation from "components/InputFieldWithValidation";
import Spinner from "components/Spinner";

import { useActiveCoupons } from "v2/hooks/billing/useActiveCoupons";

import { BillingInputName } from "v2/dataroutes/BillingData";

import { Coupon, CouponError } from "types/Coupons";

import { cx } from "utils";


interface ICouponCodeInputProps {
  targetPlanSlug?: string;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;

  fetcher?: FetcherWithComponents<Coupon | CouponError | undefined>;
}

export default function CouponCodeInput(
  {
    targetPlanSlug,
    className,
    autoFocus,
    required,
    fetcher
  }: ICouponCodeInputProps
) {
  const { activeCoupons } = useActiveCoupons();

  const internalFetcher = useFetcher<Coupon | CouponError | undefined>();
  const couponFetcher = useMemo(() => {
    return fetcher ?? internalFetcher;
  }, [ fetcher, internalFetcher ]);

  const debouncedCouponCheck = _.debounce(
    async (target: HTMLInputElement) => {
      couponFetcher.submit(target.form, {
        method: "GET",
        action: "/v2/billing/plans/coupons",
      });
    },
    500
  );

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    debouncedCouponCheck.cancel();
    debouncedCouponCheck(e.target);
  }, [ debouncedCouponCheck ]);

  return (
    <div className={ cx("min-h-[90px]", className) }>
      <input type="hidden" name={ BillingInputName.targetPlanId } value={ targetPlanSlug }/>
      <input type="hidden" name="required" value={ required ? "true" : "false" } />
      { activeCoupons?.map((coupon, idx) =>
        <input key={ idx } type="hidden" name={ BillingInputName.activeCouponCode } value={ coupon.coupon_code }/>
      ) }
      <InputFieldWithValidation
        label="Coupon Code"
        placeholder="Enter a coupon code"
        type="text"
        name={ BillingInputName.couponCode }
        onChange={ process.env.REACT_APP_REALTIME_COUPON_VALIDATION === "true" ? onChange : undefined }
        TrailingElement={ (couponFetcher.state === "loading") ? <Spinner size="XXS"/> : undefined }
        errorMessage={ couponFetcher.data ? (couponFetcher.data as CouponError).error : "" }
        successMessage={ (couponFetcher.data as Coupon | undefined)?.coupon_name }
        required={ required }
        autoFocus={ autoFocus }
      />
    </div>
  );
};
