import { useFetcherData } from "v2/hooks/useFetcherData";

import { Issue, StatuspageIssues } from "routes/dataroutes/IssuesData";


const ALL_ISSUES_FETCHER_KEY = "all-issues-fetcher";
const STATUSPAGE_ISSUES_FETCHER_KEY = "statuspage-issues-fetcher";

export function useAllIssues() {
  const { promise, value, fetcher } = useFetcherData<Issue[], { issuesPromise: Promise<Issue[]> }>({
    fetcherKey: ALL_ISSUES_FETCHER_KEY,
    fetcherHref: "/issues-data/all",
    dataPromiseAccessor: (data) => data?.issuesPromise
  });

  return {
    issuesPromise: promise,
    issues: value,
    issuesFetcher: fetcher
  };
}

export function useStatuspageIssues() {
  const { promise, value, fetcher } = useFetcherData<StatuspageIssues, { issuesPromise: Promise<StatuspageIssues> }>({
    fetcherKey: STATUSPAGE_ISSUES_FETCHER_KEY,
    fetcherHref: "/issues-data/statuspage",
    dataPromiseAccessor: (data) => data?.issuesPromise
  });

  return {
    issuesPromise: promise,
    issues: value,
    issuesFetcher: fetcher
  };
}
