import { Link } from "react-router-dom";
import _ from "lodash";
import { InformationCircleIcon } from "@heroicons/react/outline";

import { CollectorConfig } from "providers/HostedScrapingProvider/types";
import { LinkText } from "components/LinkText";
import { useBillingPagePath } from "v2/hooks/billing/useBillingPagePath";
import { useUser } from "routes/dataroutes/UserData";
import { AmazonProjectDetails, CostRow, EbayProjectDetails, GoogleProjectDetails, Separator, SummaryRow, UrlProjectDetails, WalmartProjectDetails, RedfinProjectDetails, textsAP, textsHS } from "./ProjectSummaryComponents";
import { isAmazonProject, isAsyncUrlsProject, isEbayProject, isGoogleProject, isWalmartProject, isRedfinProject, ProjectVariant } from "sdecontent";

const calculateRunPerMonth = (inProgress: boolean, creditsLeft: number, cost: number | undefined): string | number=> {
  if (inProgress || cost === undefined || cost === 0) {
    return '?'
  } else {
    return Math.floor((creditsLeft) / cost);
  }
}

export type IProjectsummarysidebarProps = {
  variant: ProjectVariant;
  details: { title: string, value: string }[];
  cost: number | undefined;
  costInProgress: boolean;
  config: CollectorConfig;
};

export const ProjectSummarySidebar = ({variant, details, config, cost, costInProgress} : IProjectsummarysidebarProps) => {
  const user = useUser();
  const nilCreditsData = _.isNil(user?.requestCount) || _.isNil(user?.apiCallLimit);
  const creditsLeft = nilCreditsData ? 0 : user!.apiCallLimit! - user!.requestCount
  const runsLeft = calculateRunPerMonth(costInProgress, creditsLeft, cost);
  const pluralS = runsLeft === 1 ? '' : 's';

  const texts = variant === 'hosted-scraper' ? textsHS : textsAP;

  return (
    <div className="p-6 h-full bg-lightestGray dark:bg-neutral-50 text-gray dark:text-neutral-600">
      <div className="text-2xl pb-6">SUMMARY</div>

      {details.map(
        ({title, value}) => <SummaryRow key={value} title={title} value={value} />
      )}

      <Separator />

      <div className="pb-4">OPTIONS & FILTERS</div>
      { isAsyncUrlsProject(config.type)
        && <UrlProjectDetails collectorConfig={config} />}
      { isAmazonProject(config.type)
        && <AmazonProjectDetails collectorConfig={config} />}
      { isGoogleProject(config.type)
        && <GoogleProjectDetails collectorConfig={config} />}
      { isWalmartProject(config.type)
        && <WalmartProjectDetails collectorConfig={config} />}
      { isEbayProject(config.type)
        && <EbayProjectDetails collectorConfig={config} />}
      { isRedfinProject(config.type)
        && <RedfinProjectDetails collectorConfig={config} />}

      <Separator />

      <CostRow texts={texts} cost={cost} costInProgress={costInProgress} />

      <Separator />

      <div className="flex flex-row gap-2">
        <div className="flex-shrink-0 w-5 h-5 text-brandPrimary dark:text-primary-600"><InformationCircleIcon /></div>
        <div className="flex-shrink">
          <p className="mb-5">{texts.content(creditsLeft, runsLeft, pluralS)}</p>
          <p>Need more credits? <Link to={ useBillingPagePath() }><LinkText>Upgrade plan</LinkText></Link></p>
        </div>
      </div>

    </div>
  )
}
