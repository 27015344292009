import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import {
  Form as ReactForm,
  useActionData,
  useFetcher,
  useLocation,
  useNavigate, useNavigation,
  useParams,
  useRevalidator,
  useRouteError
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { format } from "date-fns";

import { ReactComponent as XIcon } from "assets/icons/x-icon.svg";

import scraperApi from "api";
import { fmtCurrency, fmtDate } from "utils";
import getDataFromSuccessfulResponse from "utils/getDataFromSuccessfulResponse";
import { getCouponDiscountText, isAnnualPlan, manualRenewToThisIfScheduled } from "utils/planUtils";

import CouponCodeInput from "v2/components/billing/CouponCodeInput";
import ExtLink from "components/ExtLink";
import { useFeatureSwitch } from "components/FeatureSwitch";
import FormSubmittingSpinner from "components/FormSubmittingSpinner";
import Button from "components/Button";
import Form from "components/Form";
import Spinner from "components/Spinner";
import SubmitButton from "components/SubmitButton";
import TaggedText from "components/TaggedText";
import Toaster from "components/Toaster";

import OldUpgradeSubscriptionModal from "v2/components/billing/modals/OldUpgradeSubscriptionModal";
import UpgradeSubscriptionModal from "v2/components/billing/modals/UpgradeSubscriptionModal";
import {
  CancellationSurveyModalContent,
  CancelSubscriptionModalContent,
  CheckingUnpaidInvoicesModalContent,
  DisabledBecauseOfUnpaidInvoicesModalContent
} from "v2/components/billing/modals/CancelSubscription";
import { ErrorMessage } from "components/Modal/ErrorMessage";

import { useActiveCoupons } from "v2/hooks/billing/useActiveCoupons";
import { usePlans } from "v2/hooks/billing/usePlans";
import { useActiveSubscription } from "v2/hooks/billing/useActiveSubscription";
import { useDiscountPrice } from "v2/hooks/billing/useDiscountPrice";

import Messages from "misc/Messages";

import { useChargebeePortals } from "providers/ChargebeePortalProvider";
import { ChargebeePortalSection } from "providers/ChargebeeProvider";
import { useUserProvider } from "providers/UserProvider";

import { BillingActions, BillingInputName } from "v2/dataroutes/BillingData";
import IRouterActionError from "routes/dataroutes/IRouterActionError";
import { useUser } from "routes/dataroutes/UserData";

import { Coupon, CouponError } from "types/Coupons";


// Re-export
export { HostedScraperErrorReportModal, HostedScraperCancelJobModal, HostedScraperJobInfoModal } from 'components/Modal/HostedScraperModals';


type ModalChildren = ReactNode | ((renderProps: { closeModal: () => void }) => ReactNode);
interface IModalProps {
  headline: string;
  children: ModalChildren;
  open?: boolean;
  onClose?: () => void;
  closeOnEscapeKey?: boolean;
  closable?: boolean;
}


/**
 * This should be used for modals that are bound to a URL
 */
interface CloseModalOptions {
  blockNavigationIf?: boolean;
  goBackInHistoryOnClose?: boolean;
  goToOnClose?: string;
}

export const useCloseModal = (opts?: CloseModalOptions) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as { backgroundLocation?: Location };

  const closeModal = useCallback(() => {
    if (opts?.blockNavigationIf) {
      return;
    }

    if (opts?.goBackInHistoryOnClose) {
      navigate(-1);
    } else {
      const prevPath = opts?.goToOnClose || state?.backgroundLocation || "/";
      navigate(prevPath, { viewTransition: true });
    }
  }, [state?.backgroundLocation, navigate, opts]);

  return closeModal;
};


export default function Modal({ headline, children, open = true, onClose, closeOnEscapeKey = true }: IModalProps) {
  const closeOnEsc = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape" && onClose && closeOnEscapeKey) {
      onClose();
    }
  }, [onClose, closeOnEscapeKey]);

  useEffect(() => {
    if (closeOnEscapeKey) {
      document.addEventListener("keydown", closeOnEsc);
      return () => document.removeEventListener("keydown", closeOnEsc);
    }
  }, [onClose, closeOnEscapeKey, closeOnEsc]);

  // workaround for the 'There are no focusable elements inside the <FocusTrap />' error
  let focusRef = useRef(null);

  // TODO might need to customize the viewTransition animation here
  //  by default it's a simple cross-fade effect, but originally modals were also using a bit of y-translate
  return (
    // have to "disable" the onClose callback, so dialogs won't close on outside clicks
    <Dialog as="div" className="relative z-30" open={ open } onClose={ () => {} } initialFocus={focusRef}>
      <div className="fixed inset-0 transition-opacity bg-gray-500 dark:bg-neutral-500 bg-opacity-75 pointer-events-none backdrop-blur-sm" />

      <div className="fixed inset-0 z-30 overflow-y-auto" ref={focusRef} >
        <div className="flex items-end justify-center min-h-full p-4 sm:items-center sm:p-0">
          <DialogPanel className="relative w-full overflow-hidden transition-all transform bg-white sm:min-w-[500px] sm:max-w-min">
            <div>
              <div className="flex items-center justify-between px-5 py-4 border-b border-b-borderColor dark:border-b-neutral-200">
                <DialogTitle
                  as="span"
                  className="text-lg leading-6 text-brandDarkest dark:text-primary-800"
                >
                  {headline}
                </DialogTitle>

                { onClose && (
                  <XIcon
                    className="w-8 h-8 p-1.5 transition-colors rounded-sm cursor-pointer text-gray dark:text-neutral-600 hover:text-gray-800 dark:hover:text-neutral-800 hover:bg-slate-100"
                    onClick={onClose}
                  />
                )}
              </div>
              <>{children}</>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export function ModalContent({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex flex-col p-5 gap-y-12">
      {children}
    </div>
  );
}

export function ModalSections({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex flex-col gap-y-4 text-gray dark:text-neutral-600">
      {children}
    </div>
  );
}

export function ModalButtonsSection({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex justify-end gap-x-4 items-center">
      {children}
    </div>
  );
}

export function CancelSubscriptionModal() {
  const cancelSubscriptionFetcher = useFetcher();
  const goBack = useCloseModal({ blockNavigationIf: cancelSubscriptionFetcher.state !== "idle" });
  const showCancellationSurvey = useFeatureSwitch("REACT_APP_CANCELLATION_SURVEY_USERS");
  const unpaidInvoicesFetcher = useFetcher();

  useEffect(() => {
    if ((unpaidInvoicesFetcher.state === "idle") && !unpaidInvoicesFetcher.data) {
      unpaidInvoicesFetcher.load("/billing-data/invoices/not-paid");
    }
  });

  useEffect(() => {
    if (cancelSubscriptionFetcher.data === "OK" && !showCancellationSurvey) {
      goBack();
    }
  }, [cancelSubscriptionFetcher.data, goBack, showCancellationSurvey]);

  const ModalWrapper = useMemo(() => ({ children }: { children: JSX.Element }) => {
    return <Modal headline="Cancel Subscription" onClose={goBack}>
      {children}
    </Modal>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((unpaidInvoicesFetcher.state === "loading") || !unpaidInvoicesFetcher.data) {
    return <ModalWrapper>
      <CheckingUnpaidInvoicesModalContent />
    </ModalWrapper>;
  }

  if ((unpaidInvoicesFetcher.state === "idle") && (unpaidInvoicesFetcher.data?.count !== undefined) && (unpaidInvoicesFetcher.data.count > 0)) {
    return (
      <ModalWrapper>
        <DisabledBecauseOfUnpaidInvoicesModalContent closeModalCallback={goBack} />
      </ModalWrapper>
    );
  }

  if ((cancelSubscriptionFetcher.data === "OK") && showCancellationSurvey) {
    // subscription has been cancelled, show the cancellation survey
    return (
      <ModalWrapper>
        <CancellationSurveyModalContent closeModalCallback={goBack} />
      </ModalWrapper>
    );
  }

  if ((cancelSubscriptionFetcher.data !== "OK") || !showCancellationSurvey) {
    // subscription is not cancelled yet, or there was an error cancelling it, show the cancellation content
    return (
      <ModalWrapper>
        <CancelSubscriptionModalContent fetcher={cancelSubscriptionFetcher} closeModalCallback={goBack} />
      </ModalWrapper>
    );
  }

  return <></>; // TODO maybe we should better throw an exception here?
}

export function KeepSubscriptionModal() {
  const navigation = useNavigation();
  const goBack = useCloseModal({ blockNavigationIf: navigation.state !== "idle" });

  const { activeSubscription } = useActiveSubscription();

  return (
    <Modal headline="Reactivate Subscription" onClose={goBack}>
      <ModalContent>
        <ModalSections>
          <div>Your subscription was cancelled and will end on {fmtDate(new Date((activeSubscription?.currentTermEnd || 0) * 1000))}.</div>
          <TaggedText message="If you have any follow up questions or want to subscribe to a custom plan, please [contact our support team|contact_support]."
            tagCallbacks={{ contact_support: goBack }}
          />
          <div>You cancelled your subscription by accident? Just click on the "Reactivate subscription" button below and your subscription will be renewed with the next cycle.</div>
          {/*<ErrorMessage errorMessage={useFormattedActionError(keepSubscriptionFetcher.data)} closeModalCallback={goBack} />*/}
        </ModalSections>
        <ReactForm method="POST" action="/v2/billing">
          <ModalButtonsSection>
            <SubmitButton
              text="Reactivate subscription"
              size="MD"
              className="button button-tertiary"
              { ...BillingActions.remove_scheduled_changes }
            />
            <Button
              text="Ok"
              size="MD"
              onClick={goBack}
            />
          </ModalButtonsSection>
        </ReactForm>
      </ModalContent>
    </Modal>
  );
}

export function CancelSubscriptionChangeModal() {
  const navigation = useNavigation();
  const goBack = useCloseModal({ blockNavigationIf: navigation.state !== "idle" });


  return (
    <Modal headline="Cancel Subscription Change" onClose={goBack}>
      <ReactForm method="POST" action="/v2/billing">
        <ModalContent>
          <ModalSections>
            <div>Are you sure you want to cancel your subscription change?</div>
            <div>Your scheduled changes will be canceled</div>
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Keep Change" className="button button-secondary" onClick={goBack} size="MD" />
            <SubmitButton
              text="Cancel Change"
              className="button button-primary destructive"
              size="MD"
              { ...BillingActions.remove_scheduled_changes }
            />
          </ModalButtonsSection>
        </ModalContent>
      </ReactForm>
    </Modal>
  );
}

export function ConfirmRenewalModal() {
  const { planId } = useParams() as { planId: string };

  const user = useUser();

  const navigation = useNavigation();
  const goBack = useCloseModal({ blockNavigationIf: navigation.state !== "idle" });

  const { allPlans } = usePlans();
  const currentPlan = allPlans?.find(p => p.planSlug === planId);

  const { activeSubscription } = useActiveSubscription();
  const couponsFetcher = useFetcher<Coupon | CouponError | undefined>();
  const discountPrice = useDiscountPrice({
      useScheduledSubscriptionIfAny: manualRenewToThisIfScheduled(activeSubscription?.scheduledSubscription?.planSlug),
      coupon: getDataFromSuccessfulResponse<Coupon>(couponsFetcher.data)
  });
  const isInvoiceNeeded = !activeSubscription || (activeSubscription.nextRenewalAt === activeSubscription.currentTermEnd);

  if (user?.isRenewalAllowed) {
    return (
      <Modal headline="Confirm Renew Subscription" onClose={goBack}>
        <input type="hidden" name={ BillingInputName.chargebeeSubscriptionId } value={ activeSubscription?.chargebeeSubscriptionId } />
        <input type="hidden" name={ BillingInputName.keepScheduledChanges } value={ isAnnualPlan(activeSubscription?.planSlug) ? "true" : "false" } />
        <ReactForm method="POST" action="/v2/billing">
          <ModalContent>
            <ModalSections>
              <div>Are you sure you want to renew your subscription?</div>
              <div>
                By renewing your subscription, we will reset your credit counters
                {isInvoiceNeeded && (<>, Invoice <span className="font-bold">{fmtCurrency(discountPrice / 100)}</span> now</>)}
                {(isAnnualPlan(planId) && !isInvoiceNeeded) ? " and shorten your annual subscription by one month." : " and set your billing date to today."}
              </div>
              {/* TODO it is hardcoded here that we don't allow coupons for annual subscriptions. should we change it and let Chargebee make that decision? */}
              { (user?.canUseAllCoupons || (user?.canUseCoupons && !isAnnualPlan(currentPlan?.planSlug))) && (
                <CouponCodeInput
                  targetPlanSlug={ planId }
                  fetcher={ couponsFetcher }
                />
              ) }
              {/*<ErrorMessage errorMessage={ formError } closeModalCallback={ goBack }/>*/}
            </ModalSections>
            <ModalButtonsSection>
              <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
              <SubmitButton
                text="Renew Subscription"
                className="button button-primary"
                size="MD"
                checkFormValidity
                { ...BillingActions.renew }
              />
            </ModalButtonsSection>
          </ModalContent>
        </ReactForm>
      </Modal>
    );
  } else {
    return (
      <Modal headline="Confirm Renew Subscription" onClose={goBack}>
        <ModalContent>
          <ModalSections>
            <TaggedText message={Messages.renewalDisabledTaggedMessage} />
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Ok" className="button button-primary" onClick={goBack} size="MD" />
          </ModalButtonsSection>
        </ModalContent>
      </Modal>
    );
  }
}

function ChangeAnnualModal(
  {
    method
  }: {
    method: "Upgrade" | "Downgrade";
  }
) {
  const revalidator = useRevalidator();
  const { activeSubscription } = useActiveSubscription();
  const { allPlans } = usePlans();
  const { refresh } = useUserProvider();
  const methods = useForm();
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const [error, setError] = useState<string>();

  const methoding = method.toLowerCase().slice(0, -1) + "ing";
  const { planId } = useParams() as { planId: string };
  const targetPlan = allPlans?.find(p => p.planSlug === planId)!;
  const price = useDiscountPrice(); // not checking scheduled subscriptions here, manually upgrading or downgrading to a plan should simply drop all scheduled changes

  const onSubmit = useCallback(async () => {
    try {
      await scraperApi.subscription.update(planId, false, undefined, false);
      revalidator.revalidate();
      await refresh();
      goBack();
    } catch (error) {
      const err = error as any;
      setError(err.message || `There was an error ${methoding} your subscription`);
    }
  }, [planId, revalidator, refresh, goBack, setError, methoding]);

  // TODO should add the coupon input here as well
  return (
    <Modal headline={`${method} Subscription`} onClose={goBack}>
      <Form methods={methods} onSubmit={onSubmit}>
        <ModalContent>
          <ModalSections>
            <div>
              Are you sure you want to {method.toLowerCase()} your subscription to{" "}
              <span className="font-bold capitalize">{targetPlan.planName}</span>
              ?
            </div>
            <div>
              By {methoding} your subscription, we will reset your credit counters
              and invoice{" "}
              <span className="font-bold">{fmtCurrency(price / 100)}</span>{" "}
              on {fmtDate(new Date(activeSubscription?.currentTermEnd! * 1000))}, when your annual subscription ends.
              <TaggedText
                message={` If you would like to end your annual subscription and ${method.toLowerCase()} earlier, please [contact our support team|contact_support].`}
                tagCallbacks={{ contact_support: goBack }}
              />
            </div>
            <ErrorMessage errorMessage={error} closeModalCallback={goBack} />
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
            {/* TODO replace with <SubmitButton/> */}
            <Button
              text={`${method} Subscription`}
              className="button button-primary"
              type="submit"
              icon={{ Icon: FormSubmittingSpinner }}
              onClick={() => {
                setError(undefined);
              }}
              size="MD"
            />
          </ModalButtonsSection>
        </ModalContent>
      </Form>
    </Modal>
  );
}

export function UpgradeAnnualModal() {
  return <ChangeAnnualModal method="Upgrade" />;
}

export function UpgradeModal() {
  const useNewDialogForBillingAddress = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");
  const useNewDialogForPaymentSources = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");

  return (useNewDialogForBillingAddress || useNewDialogForPaymentSources) ?
    <UpgradeSubscriptionModal /> :
    <OldUpgradeSubscriptionModal />;
}

export function DowngradeAnnualModal() {
  return <ChangeAnnualModal method="Downgrade" />;
}

export function DowngradeModal() {
  let { planId } = useParams() as { planId: string };

  const { allPlans } = usePlans();

  const targetPlan = allPlans?.find(p => p.planSlug === planId);
  const price = useDiscountPrice({ targetPlan }); // not checking scheduled changes here, manually downgrading to a plan should simply drop all current scheduled changes

  const navigation = useNavigation();
  const goBack = useCloseModal({ blockNavigationIf: navigation.state !== "idle" });

  return (
    <Modal headline="Downgrade Subscription" onClose={ goBack }>
      <ReactForm method="POST" action="/v2/billing">
        <input type="hidden" { ...BillingActions.downgrade } />
        <input type="hidden" name={ BillingInputName.targetPlanId } value={ planId } />
        <ModalContent>
          <ModalSections>
            <div>
              Are you sure you want to downgrade your subscription to{" "}
              <span className="font-bold capitalize">
                {targetPlan?.planName}
              </span>
              ?
            </div>
            <div>
              By downgrading your subscription, we will reset your credit
              counters, Invoice{" "}
              <span className="font-bold">
                {fmtCurrency(price / 100)}{" "}
              </span>{" "}
              and update your billing date either immediately or at the end of
              current cycle.
            </div>
          </ModalSections>
          <div className="flex flex-col items-end gap-y-2 gap-x-1">
            <SubmitButton
              text="Downgrade at End of Cycle"
              className="button button-primary destructive"
              size="SM"
              name={ BillingInputName.immediate }
              value="false"
              showSubmitSpinnerIf={ () => navigation.formData?.get(BillingInputName.immediate) === "false" }
            />
            <div className="flex justify-between w-full">
              <Button text="Cancel" className="button button-secondary" onClick={goBack} size="SM" />

              <SubmitButton
                text="Downgrade Immediately"
                className="button button-primary destructive"
                size="SM"
                name={ BillingInputName.immediate }
                value="true"
                showSubmitSpinnerIf={ () => navigation.formData?.get(BillingInputName.immediate) === "true" }
              />
            </div>
          </div>
        </ModalContent>
      </ReactForm>
    </Modal>
  );
}

export function AutoRenewalModal() {
  const { percentage } = useParams() as { percentage: string };
  const navigation = useNavigation();
  const location = useLocation();
  const user = useUser();
  const goBack = useCloseModal({ blockNavigationIf: navigation.state !== "idle" });
  const actionData = useActionData() as IRouterActionError | undefined;


  if (user?.isRenewalAllowed) {
    return (
      <Modal headline="Automatic renewals" onClose={goBack}>
        <ReactForm method="POST" replace state={ location.state }>
          <input type="hidden" name={ BillingInputName.autoRenewalSetting } value={ percentage } />
          <ModalContent>
            <ModalSections>
              <div>Are you sure you want to auto renew your subscription?</div>
              <div>
                <p>
                  Your subscription will be renewed automatically after you have
                  used <span className="font-bold">{ percentage }%</span> of your
                  credits.
                </p>
              </div>
              <ErrorMessage errorMessage={ actionData?.error?.message } closeModalCallback={ goBack } />
            </ModalSections>
            <ModalButtonsSection>
              <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
              <SubmitButton
                text={ `Auto Renew at ${percentage}%`}
                className="button button-primary"
                size="MD"
                disabled={ actionData?.error !== undefined }
              />
            </ModalButtonsSection>
          </ModalContent>
        </ReactForm>
      </Modal>
    );
  } else {
    return (
      <Modal headline="Automatic renewals" onClose={goBack}>
        <ModalContent>
          <ModalSections>
            <TaggedText message={Messages.renewalDisabledTaggedMessage} />
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Ok" className="button button-primary" onClick={goBack} size="MD" />
          </ModalButtonsSection>
        </ModalContent>
      </Modal>
    );
  }
}

export function ClearAutoRenewalModal() {
  const navigation = useNavigation();
  const location = useLocation();
  const { activeSubscription } = useActiveSubscription();
  const actionData = useActionData() as IRouterActionError | undefined;

  let nextRenewalAt;

  if (activeSubscription && activeSubscription.nextRenewalAt) {
    nextRenewalAt = `(next renewal ${format(activeSubscription.nextRenewalAt * 1000, 'EEE, MMMM do yyyy')})`;
  }

  const goBack = useCloseModal({ blockNavigationIf: navigation.state !== "idle" });


  return (
    <Modal headline="Standard renewal cycle" onClose={goBack}>
      <ReactForm method="POST" replace state={ location.state }>
        <input type="hidden" name={ BillingInputName.autoRenewalSetting } value="clear" />
        <ModalContent>
          <ModalSections>
            <p>
              Are you sure you want to set your renewal to the default renewal settings?
            </p>
            <p>
              Your subscription will be renewed by the end of your billing cycle{nextRenewalAt ? ` ${nextRenewalAt}, ` : ', '}
              even if you run out of all your credits before the end of the billing cycle.
            </p>
            <p>You can learn more about our renewal feature and other options in our {' '}
              <ExtLink className="underline" to="https://docs.scraperapi.com/faq/plans-and-billing/what-happens-if-i-run-out-of-credits-before-the-end-of-my-current-subscription">documentation</ExtLink>.
            </p>
            <ErrorMessage errorMessage={ actionData?.error.message } closeModalCallback={ goBack } />
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
            <SubmitButton
              text="OK"
              className="button button-primary"
              size="MD"
              disabled={ actionData?.error !== undefined }
            />
          </ModalButtonsSection>
        </ModalContent>
      </ReactForm>
    </Modal>
  );
}

export function CouponsModal() {
  const { activeCoupons } = useActiveCoupons();
  const navigation = useNavigation();
  const goBack = useCloseModal({ blockNavigationIf: navigation.state !== "idle" });


  return (
    <Modal headline="Manage Coupons" onClose={goBack}>
      <ReactForm method="POST" action="/v2/billing">
        <div className="flex flex-col p-5 space-y-4">
          <div className="flex flex-col space-y-6 text-gray dark:text-neutral-600">
            { activeCoupons?.map((coupon, index) => {
              return (
                <div
                  key={`${coupon.coupon_code}-${coupon.coupon_type}-${index}`}
                >
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <div className="font-bold">{coupon.coupon_code}</div>
                      <div className="text-sm">
                        Active since{" "}
                        {coupon.added_at && fmtDate(new Date(coupon.added_at))}
                      </div>
                    </div>
                    <div className="">{ getCouponDiscountText(coupon) }</div>
                  </div>
                </div>
              );
            })}
            { activeCoupons?.length && (
              <hr className="text-borderColor dark:text-neutral-200" />
            )}
          </div>
          <CouponCodeInput
            className="min-h-[98px] space-y-2"
            required
            autoFocus
          />
          <ModalButtonsSection>
            <Button text="Dismiss" className="button button-secondary" onClick={goBack} size="MD" />
            <SubmitButton
              text="Add coupon"
              className="button button-primary"
              size="MD"
              checkFormValidity
              { ...BillingActions.add_coupon }
            />
          </ModalButtonsSection>
        </div>
      </ReactForm>
    </Modal>
  );
}

export function DeleteAccountModal() {
  const methods = useForm();
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const navigate = useNavigate();

  // TODO should use a form here with an action to /v2/billing ... or probably to / because the user needs to get logged out if the account got deleted
  const onSubmit = useCallback(async () => {
    try {
      const response = await scraperApi.auth.requestDeletion();

      let toasterMessage = "You sent a request to our support team to delete your account. Our team will send you a confirmation email, when your account was deleted.";

      if (response === "deleted_permanently") {
        toasterMessage = "Your request to delete your account was successful. In a few seconds you will be logged out and can not log in anymore.";
        setTimeout(async () => {
          await scraperApi.auth.logout();
          navigate("/login");
        }, 6000);
      }

      Toaster.success("Account Deletion Requested", toasterMessage);
    } catch (error) {
      Toaster.error("Requesting Account Deletion Failed", "There was an issue with your request. Please try again later or contact our support team.");
    }

    goBack();
  }, [goBack, navigate]);

  return (
    <Modal headline="Request Account Deletion" onClose={goBack}>
      <Form onSubmit={onSubmit} methods={methods}>
        <ModalContent>
          <ModalSections>
            <div>Are you sure you want to delete your account?</div>
            <div>
              By deleting your account you won't be able to login anymore
              and your API key will be invalid.
            </div>
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Keep Account" className="button button-secondary" onClick={goBack} size="MD" />
            {/* TODO replace with <SubmitButton /> */}
            <Button text="Request account deletion" className="button button-primary destructive" type="submit" size="MD" icon={{ Icon: FormSubmittingSpinner }} onClick={() => { }} />
          </ModalButtonsSection>
        </ModalContent>
      </Form>
    </Modal>
  );
}

export function BillingInfoErrorModal({ section }: { section: ChargebeePortalSection }) {
  const billingError = (useRouteError() as any).data;

  const closeModal = useCloseModal();
  const { openChargebeePortal } = useChargebeePortals();
  const revalidator = useRevalidator();

  return (
    <Modal headline={ billingError.title } onClose={ closeModal }>
      <ModalContent>
        <ModalSections>
          <div>{ billingError.message }</div>
        </ModalSections>
        <ModalButtonsSection>
          <Button text="Cancel" className="button button-secondary" size="MD" onClick={ closeModal }/>
          <Button
            text={ billingError.buttonText }
            className="button button-primary"
            size="MD"
            onClick={ () => openChargebeePortal(section, { close: revalidator.revalidate }) }
            icon={ revalidator.state === "idle" ? undefined : { Icon: Spinner } }
            disabled={ revalidator.state !== "idle" }
          />
        </ModalButtonsSection>
      </ModalContent>
    </Modal>
  );
}
