import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRelativeModalPathsForDataRoutes } from "v2/hooks/useRelativeModalPathsForDataRoutes";


export function useContactSales() {
  const location = useLocation();
  const navigate = useNavigate();
  const bookSalesCallPath = useRelativeModalPathsForDataRoutes("/book-sales-call");

  const callback = useCallback(() => {
    return navigate(bookSalesCallPath, { state: { backgroundLocation: location }, viewTransition: true });
  }, [ navigate, location, bookSalesCallPath ]);

  return { contactSalesFn: callback };
}
