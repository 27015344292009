import { useEffect, useMemo, useState } from "react";
import { FetcherWithComponents, useFetcher, useRouteLoaderData } from "react-router-dom";


export function useFetcherData<VT, DT>(
  {
    fetcherKey,
    fetcherHref,
    dataPromiseAccessor,
    routeLoaderId = ""
  }:
  {
    fetcherKey?: string,
    fetcherHref: string,
    dataPromiseAccessor?: (data: DT) => Promise<VT>,
    routeLoaderId?: string
  }
): {
  promise: Promise<VT> | undefined,
  value: VT | undefined,
  fetcher: FetcherWithComponents<DT> | undefined,
} {
  const routeLoaderData = useRouteLoaderData(routeLoaderId) as DT | undefined;

  const fetcher = useFetcher<DT>({ key: fetcherKey });

  const [ value, setValue ] = useState<VT>();

  // initial load
  useEffect(() => {
    if (!routeLoaderData && (fetcher.state === "idle") && !fetcher.data) {
      fetcher.load(fetcherHref);
    }
  });

  const dataPromise = useMemo(() => {
    if (dataPromiseAccessor && ((routeLoaderData !== undefined) || (fetcher.data !== undefined))) {
      return dataPromiseAccessor((routeLoaderData || fetcher.data)!);
    }
  }, [ dataPromiseAccessor, routeLoaderData, fetcher.data ]);

  // synchronous part
  useEffect(() => {
    if ((routeLoaderData !== undefined) || (fetcher.data !== undefined)) {
      if (dataPromise) {
        (async () => {
          setValue(await dataPromise);
        })();
      } else {
        setValue((routeLoaderData || fetcher.data) as unknown as VT);
      }
    }
  }, [ dataPromise, routeLoaderData, fetcher.data ]);

  // TODO promises returned here are a bit unreliable if used with <Suspense>-<Await> - for some reason it makes components re-render unexpectedly
  //  (probably the promise reference is changing for some reason)
  return {
    promise: dataPromise,
    value,
    fetcher: routeLoaderData ? undefined : fetcher
  };
}
