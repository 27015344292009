import { useSettingsFromApi } from "providers/ApiSettingsProvider";

// These hooks are used in UI components to get the list of TLDs for dropdowns
export const useValidAmazonTLDs = () => {
  const { availableTLDs } = useSettingsFromApi();
  return availableTLDs.amazon;
};

export const useValidGoogleTLDs = () => {
  const { availableTLDs } = useSettingsFromApi();
  return availableTLDs.google;
};

export const useValidEbayTLDs = () => {
  const { availableTLDs } = useSettingsFromApi();
  return availableTLDs.ebay;
};

export const useValidRedfinTLDs = () => {
  const { availableTLDs } = useSettingsFromApi();
  return availableTLDs.redfin;
};

export const useValidWalmartTLDs = () => {
  const { availableTLDs } = useSettingsFromApi();
  return availableTLDs.walmart;
};