import { Route } from "react-router-dom";

import * as Modal from "components/Modal";


export default function GlobalModals() {
  return [
    <Route
      key="api-keys"
      path="api-keys"
      element={ <Modal.ApiKeyManagement /> }
    />,

    <Route
      key="book-sales-call"
      path="book-sales-call"
      element={ <Modal.BookSalesCallModal /> }
    />,

    <Route
      key="change-password"
      path="change-password"
      element={ <Modal.ChangePasswordModal /> }
    />,

  ];
};
