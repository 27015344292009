import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import scraperApi from "api";
import { defaultCountryCodes } from "components/hosted-scraping/extraApiParams";
import axios from "axios";

export type SettingsFromApi = {
  availableCountryCodes: string[];
  availableTLDs: {
    amazon: string[];
    google: string[];
    ebay: string[];
    redfin: string[];
    walmart: string[];
  };
};

let SettingsFromApiContext = createContext<SettingsFromApi>({
  availableCountryCodes: defaultCountryCodes,
  availableTLDs: {
    amazon: [],
    google: [],
    ebay: [],
    redfin: [],
    walmart: []
  }
});

export default function ApiSettingsProvider({ children }: { children: ReactNode }) {
  const [settings, setSettings] = useState<SettingsFromApi>({
    availableCountryCodes: defaultCountryCodes,
    availableTLDs: {
      amazon: [],
      google: [],
      ebay: [],
      redfin: [],
      walmart: []
    }
  });

  const fetchSettings = useCallback(
    async (controller: AbortController | undefined) => {
      try {
        const [countryCodesResponse, tldsResponse] = await Promise.all([
          scraperApi.settings.availableCountryCodes({ signal: controller?.signal }),
          scraperApi.settings.availableTLDs({ signal: controller?.signal })
        ]);

        setSettings({
          availableCountryCodes: countryCodesResponse,
          availableTLDs: tldsResponse
        });
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      }
    },
    []
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchSettings(controller);
    return () => controller.abort();
  }, [fetchSettings]);

  return (
    <SettingsFromApiContext.Provider value={settings}>
      {children}
    </SettingsFromApiContext.Provider>
  );

};

export function useSettingsFromApi() {
  return useContext(SettingsFromApiContext);
}
