import { useNewDashboardDesign } from "hooks/useNewDashboardDesign";
import PilotFeatureAvailableBanner from "./PilotFeatureAvailableBanner";


export default function NewDashboardDesignAvailableBanner() {
  const { newDashboardDesignSetting, updateNewDashboardDesignSetting } = useNewDashboardDesign();

  return <PilotFeatureAvailableBanner
    text="Would you like to take a look at our new dashboard design?"
    setting={ newDashboardDesignSetting }
    updateSetting={ updateNewDashboardDesignSetting }
  />;
};
