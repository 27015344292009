import { useCallback } from "react";

import { useFeatureSwitch } from "components/FeatureSwitch";

import { usePilotFeature, PilotFeatureSetting } from "v2/hooks/pilot/usePilotFeature";

import { trackNewDesignBannerOptionClicked } from "utils/Tracking";


export function useNewDashboardDesign() {
  const {
    isPilotEnabled,
    pilotFeatureSetting,
    updatePilotFeatureSetting
  } = usePilotFeature("saTryNewDashboardDesign", "REACT_APP_NEW_DASHBOARD_DESIGN_PILOT_USERS");
  const forceNewDashboardDesign = useFeatureSwitch("REACT_APP_NEW_DASHBOARD_DESIGN_NEW_SIGNUPS_AFTER");

  const updateNewDashboardDesignSetting = useCallback((newValue: PilotFeatureSetting) => {
    updatePilotFeatureSetting(newValue);
    trackNewDesignBannerOptionClicked(newValue);
  }, [ updatePilotFeatureSetting ]);

  return {
    newDashboardDesignSetting: forceNewDashboardDesign ? "yes" : pilotFeatureSetting,
    newDesignForced: forceNewDashboardDesign,
    updateNewDashboardDesignSetting: (!forceNewDashboardDesign && isPilotEnabled) ? updateNewDashboardDesignSetting : () => {}
  };
}
