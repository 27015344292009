import scraperApi from "api";
import { IssueSeverity } from "providers/IssuesProvider";


export interface StatuspageIssues {
  mostRecentIssue: any;
  numActiveIssues: number;
  numRecentlyResolvedIssues: number;
}

export interface Issue {
  id: string;
  severity: IssueSeverity;
  title: string;
  happenedAt: Date;
  action: string;
  dismissible: boolean;
  issueStatusUrl?: string;
}

export async function allIssuesLoader({ request }: { request: Request }) {
  return {
    issuesPromise: scraperApi.status.allIssues({ signal: request.signal })
  };
}

export async function statuspageIssuesLoader({ request }: { request: Request }) {
  return {
    issuesPromise: scraperApi.status.issues({ signal: request.signal })
  };
}
