import { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";

import Button from "components/Button";
import Card from "components/Card";
import ExtLink from "components/ExtLink";
import Spinner from "components/Spinner";

import { RecentlyViewedPage } from "providers/RecentlyViewed/RecentlyViewedProvider";


interface IChangelogEntry {
  summary: string;
  updatedAt: string;
  readMoreLink: string;
  showDivider?: boolean;
}

function ChangelogEntry(
  {
    summary,
    updatedAt,
    readMoreLink,
    showDivider = false
  }: IChangelogEntry
) {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-1.5">
        <div className="text-sm text-neutral-900">
          { summary }
        </div>
        <div className="flex flex-row justify-between text-xs text-neutral-700">
          <div>{ updatedAt }</div>
          <ExtLink
            to={ readMoreLink }
            tracked
            trackedTitle={ summary }
            className="underline hover:text-neutral-900"
          >
            Read more
          </ExtLink>
        </div>
      </div>
      { showDivider && (
        <div className="border-t-[1px] border-neutral-50 w-full" />
      ) }
    </div>
  );
}

export type ChangeLogData = { viewAllLink: string, updates: IChangelogEntry[] };

export default function WhatsNewCard(
  {
    className,
  }: {
    className?: string;
  }
) {
  const changelogFetcher = useFetcher<ChangeLogData>({ key: "changelog-fetcher" });

  const [ isChangelogLoaded, setChangelogLoaded ] = useState(false);

  useEffect(() => {
    if ((changelogFetcher.state === "idle") && ((changelogFetcher.data === undefined) || !isChangelogLoaded)) {
      changelogFetcher.load("/dashboard-data/changelog");
      setChangelogLoaded(true);
    }
  }, [ changelogFetcher, isChangelogLoaded, setChangelogLoaded ]);


  return (
    <RecentlyViewedPage
      title="Release notes"
      iconName="Documentation"
      location={ changelogFetcher.data?.viewAllLink }
    >
      <Card
        className={ className }
        title={ <div className="flex flex-row gap-x-2 items-center">What's new? { changelogFetcher.state === "loading" && <Spinner size="XXS" /> }</div> }
        extra={
          <Button
            text="View all"
            size="SM"
            href={ changelogFetcher.data?.viewAllLink }
            className="button button-text-primary text-xs"
            tracked
          />
        }
      >
      { (changelogFetcher.data !== undefined) && (
          <div className="flex flex-col gap-y-4">
            { changelogFetcher.data?.updates?.map((entry, idx, allEntries) => (
              <ChangelogEntry key={ idx } { ...entry } showDivider={ idx < allEntries.length - 1 } />
            )) }
          </div>
        ) }
      </Card>
    </RecentlyViewedPage>
  );
};
