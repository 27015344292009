import axios from "axios";
import { createContext, ReactNode, useCallback, useContext, useState } from "react";
import scraperApi from "api";

export type IssueSeverity = 'blocking' | 'failure' | 'warning' | 'info';

export interface Issue {
  id: string;
  severity: IssueSeverity;
  title: string;
  happenedAt: Date;
  action: string;
  dismissible: boolean;
  issueStatusUrl?: string | undefined;
}

export type IssuesContextType = {
  refresh: (controller: AbortController | undefined) => Promise<void>;
  issues: Issue[] | undefined;
};

let IssuesContext = createContext<IssuesContextType>(null!);

/**
 * @deprecated instead of using this provider one should rather use the new v2 hooks: useAllIssues and useStatuspageIssues
 * TODO the only thing preventing the usage of the new hooks is the refresh function here - for that we should create an action for issues,
 *   so the fetchers will get refreshed automatically by the react router
 *
 * @param children
 * @constructor
 */
export default function IssuesProvider({ children }: { children: ReactNode }) {

  const [ issues, setIssues ] = useState<Issue[]>();

  const fetchIssues = useCallback(
    async (controller: AbortController | undefined) => {
      try {
        const allIssues = await scraperApi.status.allIssues({
          signal: controller?.signal
        });

        setIssues(allIssues);
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.error(e);
        }
      }
    },
    []
  );

  const value = {
    refresh: fetchIssues,
    issues
  };

  return (
    <IssuesContext.Provider value={value}>
      {children}
    </IssuesContext.Provider>
  );

};

/**
 * @deprecated should use v2 hooks instead: useAllIssues and useStatuspageIssues
 */
export function useIssues() {
  return useContext(IssuesContext);
}
