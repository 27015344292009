import { json, redirect } from "react-router-dom";
import { AxiosRequestConfig } from "axios";

import scraperApi, { ApiError, ApiErrorResponse, ChargebeeApiErrorResponse } from "api";

import { ActiveSubscriptionLoaderDataType } from "v2/hooks/billing/useActiveSubscription";
import { CouponsLoaderDataType } from "v2/hooks/billing/useActiveCoupons";

import IRouterActionError from "routes/dataroutes/IRouterActionError";
import OkResponse from "routes/dataroutes/OkResponse";

import { join } from "utils";
import { formDataToStructuredObject } from "utils/formDataUtils";

import ActiveSubscription from "types/ActiveSubscription";


export const BillingInputName = {
  action: "billing-action",
  activeCouponCode: "active-coupon-code",
  autoRenewalSetting: "auto-renewal-setting",
  billingAddress: "billing-address",
  chargebeeSubscriptionId: "chargebee-subscription-id",
  couponCode: "coupon-code",
  immediate: "immediate",
  keepScheduledChanges: "keep-scheduled-changes",
  paymentSource: "payment-source",
  targetPlanId: "target-plan-id",
};

function billingFormAction(actionValue: string) {
  return {
    name: BillingInputName.action,
    value: actionValue
  };
}

export const BillingActions = {
  add_coupon: billingFormAction("add-coupon"),
  downgrade: billingFormAction("downgrade"),
  remove_scheduled_changes: billingFormAction("remove-scheduled-changes"),
  renew: billingFormAction("renew"),
  upgrade: billingFormAction("upgrade"),
};


export async function removeScheduledChangesAction(): Promise<ActiveSubscription | IRouterActionError> {
  try {
    return await scraperApi.subscription.removeScheduledChanges();
  } catch (error) {
    return {
      error: {
        taggedMessage: {
          message: "There was an error processing your request. Please try again later or [contact our support team|contact_support]"
        }
      }
    };
  }
}

export async function checkCouponsLoader({ request }: { request: Request }) {
  const url = new URL(request.url);
  const couponCode = url.searchParams.get(BillingInputName.couponCode);
  const required = url.searchParams.get("required") === "true";

  if (!couponCode) {
    // empty coupon code input, clear coupon data
    return {
      coupon_name: "",
      coupon_code: "",
      error: required ? "Coupon code must not be empty" : undefined
    };
  }

  const targetPlanId = url.searchParams.get(BillingInputName.targetPlanId);
  const activeCouponCodes = url.searchParams.getAll(BillingInputName.activeCouponCode);
  if (activeCouponCodes?.includes(couponCode)) {
    return {
      coupon_code: couponCode,
      error: "That coupon has already been used."
    };
  }

  try {
    const couponResponse = await scraperApi.subscription.checkCoupons([ couponCode ], targetPlanId || undefined, { signal: request.signal });
    return couponResponse[0];
  } catch (err) {
    return {
      coupon_code: couponCode,
      error: (err as Error).message
    };
  }
}

export async function billingAddressLoader() {
  const billingAddress = await scraperApi.subscription.billingAddress();
  return billingAddress || {};
}

export async function paymentSourcesLoader() {
  const paymentSources = await scraperApi.subscription.paymentSources();
  return paymentSources || [];
}

async function addCouponsAction(
  {
    couponCodes,
    targetPlanSlug
  }: {
    couponCodes: string[],
    targetPlanSlug?: string;
  },
  opts?: AxiosRequestConfig
) {
  return scraperApi.subscription.addCoupons(couponCodes, targetPlanSlug, opts);
}

export function activeSubscriptionLoader({ request }: { request: Request }): ActiveSubscriptionLoaderDataType {
  return {
    activeSubscriptionPromise: scraperApi.subscription.active({ signal: request.signal })
  };
}

export function plansLoader({ request }: { request: Request }) {
  return {
    plansPromise: scraperApi.billing.getPlans({ signal: request.signal })
  };
}

export async function validatePaymentMethod({ request }: { request: Request }) {
  const paymentMethod = await scraperApi.subscription.paymentMethod({ signal: request.signal });

  let errorResponse;

  if (!paymentMethod) {
    errorResponse = {
      title: "Payment Method Error",
      message: "Missing payment method",
      buttonText: "Add payment method"
    };
  } else if (paymentMethod.status === "expired") {
    errorResponse = {
      title: "Payment Method Error",
      message: "Your payment method has expired",
      buttonText: "Update payment method"
    };
  } else if (paymentMethod.status === "invalid") {
    errorResponse = {
      title: "Payment Method Error",
      message: "Your payment method is invalid",
      buttonText: "Update payment method"
    };
  }

  if (errorResponse) {
    // throwing so it will go to the error path
    throw json(errorResponse, { status: 400 });
  }

  return new OkResponse();
}

const mandatoryAddressFields = {
  first_name: "first name",
  last_name: "last name",
  line1: "address line 1",
  city: "city",
  state: "state",
  country: "country",
  zip: "zip code"
};

export async function validateBillingAddress({ request }: { request: Request }) {
  const billingAddress = await scraperApi.subscription.billingAddress({ signal: request.signal });

  let errorResponse;

  if (!billingAddress) {
    errorResponse = {
      title: "Billing Address Error",
      message: "Missing billing address",
      buttonText: "Set billing address"
    };
  } else {
    const missingFields = [];
    for (const [ field, fieldName ] of Object.entries(mandatoryAddressFields)) {
      // backward compatibility, backend was used to sending the values only, now it sends the values combined with validation related properties
      if (!billingAddress[field] || (typeof billingAddress[field] === 'object' && !billingAddress[field].value)) {
        missingFields.push(fieldName);
      }
    }
    if (missingFields.length > 0) {
      errorResponse = {
        title: "Billing Address Error",
        message: `Your billing information is incomplete. Please add ${ join(missingFields, ", ", " and ") } to your billing address.`,
        buttonText: "Update billing address"
      };
    }
  }

  if (errorResponse) {
    // throwing so it will go to the error path
    throw json(errorResponse, { status: 400 });
  }

  return new OkResponse();
}

async function updatePaymentSourceAction(paymentSource: any, opts?: AxiosRequestConfig) {
  if (paymentSource) {
    try {
      await scraperApi.billing.setPaymentSource(paymentSource, opts);
    } catch (err) {
      // TODO error handling
      const error = err as any;
      if ((error.error_code === "chargebee_error") && (error.details.chargebee_api_error_code === "payment_method_verification_failed")) {

        const errors: { [index: string]: string } = {};

        if ((error.details.chargebee_error_code === "add_card_error") && (error.details.message.toLowerCase().endsWith("expired card."))) {
          errors["expiry_year"] = "Your card is expired";
          return {
            error: {
              formInputErrors: errors
            }
          };
        }

        const invalidFieldMatcher = (error.details.message as string).match(/^card\[(\w+)] : (.*)/);
        if (invalidFieldMatcher && invalidFieldMatcher[1]) {
          if ((invalidFieldMatcher[1] === "expiry_month") && (error.details.chargebee_error_code === "not_in_allowed_range")) {
            errors["expiry_month"] = "Month is not in range";
          } else {
            errors[invalidFieldMatcher[1]] = invalidFieldMatcher[2] || "Invalid value";
          }
          return {
            error: {
              formInputErrors: errors
            }
          };
        }
      }

      // bubble up
      throw err;
    }
  }
}

async function updateBillingAddressAction(billingAddress: any, opts?: AxiosRequestConfig) {
  if (billingAddress) {
    try {
      // passing the country code only
      await scraperApi.billing.setBillingAddress({ ...billingAddress, country: billingAddress.country?.countryCode }, opts);
    } catch (err) {
      // TODO return detailed error message, so the billing address form can display that
      const error = err as any;
      if ((error.error_code === "chargebee_error") && (error.details.chargebee_api_error_code === "param_wrong_value")) {
        const invalidFieldMatcher = (error.details.message as string).match(/^billing_address\[(\w+)] : (.*)/);
        if (invalidFieldMatcher && invalidFieldMatcher[1]) {
          const errors: { [index: string]: string } = {};
          errors[invalidFieldMatcher[1]] = invalidFieldMatcher[2] || "Invalid value";
          return {
            error: {
              formInputErrors: errors
            }
          };
        }
      }

      // bubble up
      throw err;
    }
  }
}

async function changeSubscriptionAction(
  {
    targetPlanId,
    immediate,
    coupons,
    keepScheduledChanges
  }: {
    targetPlanId: string;
    immediate: boolean;
    coupons?: string[];
    keepScheduledChanges: boolean;
  },
  opts?: AxiosRequestConfig
) {
  try {
    return await scraperApi.subscription.update(targetPlanId, immediate, coupons, keepScheduledChanges, opts);
  } catch (err) {
    const error = err as ApiError<ApiErrorResponse>;
    if (error.error_code === "chargebee_error") {
      const chargebeeError = error.details as ChargebeeApiErrorResponse;
      if (chargebeeError.chargebee_api_error_code === "payment_processing_failed") {
        return {
          // TODO both chargebeeError.message and chargebeeError.details hold useful information. we should somehow return both and display it to the user
          error: {
            taggedMessage: {
              message: chargebeeError.message || "Payment processing failed"
            }
          }
        }
      }
    }
    // TODO proper error handling here

    // bubble up
    throw err;
  }
}

async function renewSubscriptionAction(
  {
    subscriptionId,
    coupons,
    keepScheduledChanges,
  }: {
    subscriptionId: string;
    coupons?: string[];
    keepScheduledChanges: boolean;
  },
  opts?: AxiosRequestConfig
) {
  try {
    return await scraperApi.subscription.renew(subscriptionId, coupons, keepScheduledChanges, opts);
  } catch (err) {
    if (err instanceof ApiError) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const apiError = err as ApiError<ApiErrorResponse>;
      // TODO proper error handling
      // if (isCouponError(apiError)) {
      //   return setError("coupon", {
      //     type: "custom",
      //     message: apiError.details?.message || apiError.message
      //   });
      // }
      //
      // if (apiError.error_code === "err_recently_renewed") {
      //   return setFormError(apiError.message);
      // }
    }
  }
}

export async function changeAutoRenewalAction({ request }: { request: Request }): Promise<Response | IRouterActionError> {
  const formData = formDataToStructuredObject(await request.formData());

  // TODO should we also update payment sources and billing address? we're not providing inputs for them during auto-renewal changes

  try {
    await scraperApi.subscription.changeAutoRenewal(formData[BillingInputName.autoRenewalSetting], { signal: request.signal });
    // all ok, return to the billing page
    return redirect("/v2/billing");
  } catch (err) {
    // TODO proper error handling should come here
    const apiError = err as ApiError<ApiErrorResponse>;

    if (apiError.error_code) {
      return {
        error: {
          message: apiError.message
        }
      };
    }

    // bubble up
    throw err;
  }
}

export async function subscriptionActions({ request }: { request: Request }) {
  const formData = formDataToStructuredObject(await request.formData());

  const requestConfig: AxiosRequestConfig = { signal: request.signal };

  switch (formData[BillingInputName.action]) {
    case BillingActions.add_coupon.value:
      return await addCouponsAction(
        {
          couponCodes: [ formData[BillingInputName.couponCode] ],
          targetPlanSlug: formData[BillingInputName.targetPlanId]
        },
        requestConfig
      );

    case BillingActions.downgrade.value:
      await updatePaymentSourceAction(formData[BillingInputName.paymentSource], requestConfig);
      await updateBillingAddressAction(formData[BillingInputName.billingAddress], requestConfig);
      return await changeSubscriptionAction(
        {
          targetPlanId: formData[BillingInputName.targetPlanId],
          immediate: formData[BillingInputName.immediate] === "true",
          keepScheduledChanges: false
        },
        requestConfig
      );

    case BillingActions.renew.value:
      // TODO if we'd like to display errors on the billing page we have to return IRouterActionError instances like this:
      // return {
      //   error: {
      //     message: "test error"
      //   }
      // } as IRouterActionError;

      await updatePaymentSourceAction(formData[BillingInputName.paymentSource], requestConfig);
      await updateBillingAddressAction(formData[BillingInputName.paymentSource], requestConfig);
      return await renewSubscriptionAction(
        {
          subscriptionId: formData[BillingInputName.chargebeeSubscriptionId],
          coupons: [ formData[BillingInputName.couponCode] ],
          keepScheduledChanges: formData[BillingInputName.keepScheduledChanges] === "true"
        },
        requestConfig
      );

    case BillingActions.upgrade.value:
      await updatePaymentSourceAction(formData[BillingInputName.paymentSource], requestConfig);
      await updateBillingAddressAction(formData[BillingInputName.billingAddress], requestConfig);
      return await changeSubscriptionAction(
        {
          targetPlanId: formData[BillingInputName.targetPlanId],
          immediate: true,
          coupons: [ formData[BillingInputName.couponCode] ],
          keepScheduledChanges: false
        },
        requestConfig
      );

    case BillingActions.remove_scheduled_changes.value:
      return await removeScheduledChangesAction();

    default:
      throw new Error("Not implemented yet.");
  }

}

export function activeCouponsLoader({ request }: { request: Request }): CouponsLoaderDataType {
  return {
    couponsPromise: scraperApi.billing.getActiveCoupons({ signal: request.signal })
  };
}
