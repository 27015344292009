import { Banner } from "components/IssuesBanner/Banner";
import { PilotFeatureSetting } from "v2/hooks/pilot/usePilotFeature";


export default function PilotFeatureAvailableBanner(
  {
    text,
    setting,
    updateSetting,
  }: {
    text: string,
    setting: PilotFeatureSetting | undefined,
    updateSetting: (newSetting: PilotFeatureSetting) => void,
  }
) {
  if (setting) {
    return <></>;
  }

  return (
    <Banner
      title={
        <span className="flex gap-x-2 items-center justify-center text-sm text-primary-800">
          <span>{ text }</span>
          <button className="hover:underline hover:text-primary-900" onClick={ () => updateSetting("yes") }>Yes</button>
          <button className="hover:underline hover:text-primary-900" onClick={ () => updateSetting("no") }>No</button>
          <button className="hover:underline hover:text-primary-900" onClick={ () => updateSetting("never") }>Don't ask again</button>
        </span>
      }
      dismissible={ true }
      severity="info"
    />
  );
};
