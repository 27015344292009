import { useMemo } from "react";

import { useActiveCoupons } from "v2/hooks/billing/useActiveCoupons";
import { useActiveSubscription } from "v2/hooks/billing/useActiveSubscription";

import { getDiscountPriceFor } from "utils/planUtils";

import { Coupon } from "types/Coupons";
import Plan from "types/Plan";


function mergeCoupons(coupons: Coupon[] | undefined, coupon: Coupon | undefined): Coupon[] | undefined {
  // TODO add logic here to match Chargebee's behavior
  //  only one coupon of the same kind can be active at a time, so e.g. if there's already an active -20% coupon
  //  and the user enters a code for another % coupon, that should replace the existing one and should not get added
  //  on top of that
  if (coupons) {
    return coupon ? [ ...coupons, coupon ] : coupons;
  } else {
    return coupon ? [ coupon ] : undefined;
  }
}

type UseDiscountPriceArgs = {
  useScheduledSubscriptionIfAny?: boolean;
  coupon?: Coupon;
  targetPlan?: Plan;
};

export function useDiscountPrice(args?: UseDiscountPriceArgs) {
  const { activeSubscription } = useActiveSubscription();
  const { activeCoupons } = useActiveCoupons();

  const target = useMemo(() => {
    if (args?.targetPlan) {
      return args.targetPlan;
    }

    if (args?.useScheduledSubscriptionIfAny && activeSubscription?.scheduledSubscription) {
      return activeSubscription.scheduledSubscription;
    }

    return activeSubscription;
  }, [ activeSubscription, args ]);

  const discountPrice = useMemo(() => {
    return getDiscountPriceFor(target, mergeCoupons(activeCoupons, args?.coupon));
  }, [ target, activeCoupons, args ]);

  return discountPrice;
}
