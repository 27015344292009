export const FetcherKeys = {
  ACTIVE_COUPONS: "active-coupons-fetcher",
  ACTIVE_SUBSCRIPTION: "active-subscription-fetcher",
  BILLING_ADDRESS: "billing-address-fetcher",
  CHARTS_DATA: "charts-data-fetcher",
  COUPON: "coupon-fetcher",
  PAYMENT_SOURCES: "payment-sources-fetcher",
  PLANS: "plans-fetcher",
  UPGRADE_SUBSCRIPTION: "upgrade-subscription-fetcher",
}

export const Fetchers = {
  ACTIVE_COUPONS_FETCHER: { key: FetcherKeys.ACTIVE_COUPONS },
  ACTIVE_SUBSCRIPTION_FETCHER: { key: FetcherKeys.ACTIVE_SUBSCRIPTION },
  BILLING_ADDRESS_FETCHER: { key: FetcherKeys.BILLING_ADDRESS },
  CHARTS_DATA: { key: FetcherKeys.CHARTS_DATA },
  COUPON_FETCHER: { key: FetcherKeys.COUPON },
  PAYMENT_SOURCES_FETCHER: { key: FetcherKeys.PAYMENT_SOURCES },
  PLANS_FETCHER: { key: FetcherKeys.PLANS },
  UPGRADE_SUBSCRIPTION_FETCHER: { key: FetcherKeys.UPGRADE_SUBSCRIPTION },
};
