import { useCallback, useMemo, useState } from "react";

import { useFeatureSwitch } from "components/FeatureSwitch";
import { FeatureSwitch } from "components/FeatureSwitch/FeatureSwitches";

import { getCookie, ScraperAPICookies, setCookie } from "utils/cookieUtils";


export type PilotFeatureSetting = "yes" | "no" | "never";

export function usePilotFeature(
  cookieName?: ScraperAPICookies,
  pilotEnabledFeatureSwitch?: FeatureSwitch
) {
  const [ pilotFeatureSetting, setPilotFeatureSetting ] = useState<PilotFeatureSetting | undefined>(cookieName ? getCookie(cookieName) as PilotFeatureSetting : undefined);
  const pilotFeatureSwitch= useFeatureSwitch(pilotEnabledFeatureSwitch);
  const isPilotEnabled = useMemo(() => !!(pilotEnabledFeatureSwitch && pilotFeatureSwitch), [ pilotEnabledFeatureSwitch, pilotFeatureSwitch ]);

  const updatePilotFeatureSetting = useCallback((newValue: PilotFeatureSetting) => {
    if (cookieName) {
      // store negative answers for 1 week only
      setCookie(cookieName, newValue, newValue === "no" ? { expires: 7 } : undefined);
    }

    setPilotFeatureSetting(newValue);
  }, [ cookieName ]);

  return {
    isPilotEnabled: isPilotEnabled,
    pilotFeatureSetting: isPilotEnabled ? pilotFeatureSetting : "no",
    updatePilotFeatureSetting: isPilotEnabled ? updatePilotFeatureSetting : () => {}
  };
}
