import { useFetcherData } from "v2/hooks/useFetcherData";
import { FetcherKeys } from "routes/dataroutes/Fetchers";
import { Coupon } from "types/Coupons";


export type CouponsLoaderDataType = { couponsPromise: Promise<Coupon[]> };

export function useActiveCoupons() {
  const { promise, value, fetcher } = useFetcherData<Coupon[], CouponsLoaderDataType>({
    fetcherKey: FetcherKeys.ACTIVE_COUPONS,
    fetcherHref: "/billing-data/coupons",
    dataPromiseAccessor: (data) => data?.couponsPromise
  });

  return {
    activeCouponsPromise: promise,
    activeCoupons: value,
    activeCouponsFetcher: fetcher
  };
}
